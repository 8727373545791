import React, { useState, useEffect, useRef } from "react";
import { Image, InputGroup, InputLeftElement, Stack, Input, Flex, Button, Box, Heading, Divider, Text, Progress } from "@chakra-ui/react";
import MarkerLogo from "../../../images/google-marker.svg";

import { useApi } from "../../../hooks/customer/useApi";
import AnchorLogo from "../../../images/anchor.svg";

import {
  loadGMaps,
  createMap,
  initDirectionsService,
  initDirectionsDisplay,
  showDestinationIcon,
  buildDirectionsRequest,
  initJourneyStartMarker,
  extendBoundsForLocation,
  positionMarker,
} from "../../../scripts/google-maps-helper.js";
import VariedText from "../../VariedText/VariedText";
import { useNavigate } from "react-router-dom";

function JourneyToPortmagee() {
  const [state, setState] = useState({ checkinConfig: { configId: -1 }, currentStartLocation: {}, currentJourneyMetrics: {}, journeyInstructions: {} });
  const navigate = useNavigate();

  const directionsDisplay = useRef();
  const journeyStartMarker = useRef();

  const api = useApi();

  useEffect(() => {
    api.checkAuth();
    loadGMaps(async () => {
      const map = createMap();
      showDestinationIcon(map, AnchorLogo);
      journeyStartMarker.current = initJourneyStartMarker(map);
      const directionsService = initDirectionsService();
      directionsDisplay.current = initDirectionsDisplay(map);

      const data = await api.getPageData("journey-to-portmagee");
      if (data) {
        setState((oldState) => {
          return { ...oldState, ...data };
        });
        if (data.currentStartLocation) {
          updateFromLocation(map, directionsService, data.currentStartLocation.locationLatLong);
        }
      }
    });
    return () => {};
  }, []);

  function routeAndRenderNewDirections(map, directionsService, request) {
    directionsService.route(request, (result, status) => {
      if (status === "OK") {
        directionsDisplay.current.setMap(map);
        directionsDisplay.current.setDirections(result);
      } else {
        //TODO Show error that there is no route being found and to input location on previous page maybe?
      }
    });
  }

  function updateFromLocation(map, directionsService, location) {
    extendBoundsForLocation(map, location);
    positionMarker(journeyStartMarker.current, location, true);

    routeAndRenderNewDirections(map, directionsService, buildDirectionsRequest(location));
  }

  async function handleContinue(e) {
    // e.preventDefault();
    const payload = { configId: state.checkinConfig.configId };
    if (await api.postProceed("journey-to-portmagee", payload)) {
      window.history.pushState({}, undefined, "/contact-details");
      navigate("/contact-details", { replace: true });
    }
  }

  async function handleBack() {
    navigate(-1);
  }

  return (
    <Flex direction="column" align="center" w="full">
      <Box p={5} width="100%" maxWidth={700} minHeight={"100vh"} borderWidth={1} borderRadius={8} boxShadow="lg">
        <Progress value={60}></Progress>
        {/* <Box textAlign="left"> */}
        {/* <Heading>Your Journey</Heading> */}
        {/* </Box> */}
        {state.journeyInstructions.formattedExtremeJourney && (
          <Text mt={2} textAlign="left">
            {state.journeyInstructions.formattedExtremeJourney}
          </Text>
        )}
        {state.journeyInstructions.timeToLeaveArray && (
          <Text mt={2} textAlign="left">
            <VariedText variedTextArray={state.journeyInstructions.timeToLeaveArray}></VariedText>
          </Text>
        )}
        {state.journeyInstructions.unfamiliarWithRoadsArray && (
          <Text mt={2} textAlign="left">
            <VariedText variedTextArray={state.journeyInstructions.unfamiliarWithRoadsArray}></VariedText>
          </Text>
        )}
        {state.journeyInstructions.formattedSlowMovingTraffic && (
          <Text mt={2} textAlign="left">
            {state.journeyInstructions.formattedSlowMovingTraffic} {state.journeyInstructions.gpsWarning && state.journeyInstructions.gpsWarning}
          </Text>
        )}
        <Stack mt={3}>
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<Image maxHeight={6} src={MarkerLogo} alt="Journey Start" />} />
            <Input id="pac-input" readOnly={true} backgroundColor="gray.100" color="gray.500" defaultValue={state.currentStartLocation.locationText} />
          </InputGroup>
        </Stack>
        <Stack mt={3}>
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<Image maxHeight={6} src={AnchorLogo} alt="Portmagee Marina" />} />
            <Input id="pac-input" readOnly={true} backgroundColor="gray.100" color="gray.500" value="Portmagee Marina, Co. Kerry" />
          </InputGroup>
        </Stack>
        <Text mt="2" textAlign="center">
          <b>Minimum time by car:</b> {state.currentJourneyMetrics.formattedDuration}
        </Text>
        <Text mt="2" textAlign="center">
          <b>Journey distance:</b> {state.currentJourneyMetrics.formattedDistance}
        </Text>
        {api.errorMessage && (
          <Text mt="3" minHeight="10" color="red.500">
            {api.errorMessage}
          </Text>
        )}
        <Divider mt={3} />
        <Flex justifyContent="space-between" my="3">
          <Button isLoading={api.isLoading} onClick={handleBack} type="submit" variant="solid" colorScheme="gray">
            Back
          </Button>
          <Button isLoading={api.isLoading} onClick={handleContinue} type="submit" variant="solid" colorScheme="green">
            Next
          </Button>
        </Flex>
        {/* <Box mt="5">{loaded ? <GoogleMap /> : ""}</Box> */}
        <div id="map" style={{ height: "400px" }} />
      </Box>
    </Flex>
  );
}

export default JourneyToPortmagee;
