import { Button, Flex, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

function DatePickerComponent({ chosenDate, handleDateChange }) {
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Button onClick={onClick} ref={ref} size={"sm"} colorScheme={"green"} variant={"outline"}>
      {value}
    </Button>
  ));
  return (
    <Flex textAlign={"center"}>
      <DatePicker selected={chosenDate} onChange={(date) => handleDateChange(date)} customInput={<CustomInput />} dateFormat={"dd/MM/yyyy"} />
    </Flex>
  );
}

export default DatePickerComponent;
