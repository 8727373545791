import React, { useEffect, useState } from "react";
import { Flex, Divider, Button, Box, Heading, Text, Progress, UnorderedList, ListItem, Image, Textarea } from "@chakra-ui/react";
import { useApi } from "../../../hooks/customer/useApi";
// import {authContext} from "../contexts/AuthContext";
import VariedText from "../../VariedText/VariedText";
import AnchorLogo from "../../../images/anchor.svg";
import { useNavigate } from "react-router-dom";

function Finish() {
  // const authContext = useContext(authContext);
  const [state, setState] = useState({ customerWrittenComment: "" });
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    api.checkAuth();
    async function initPage() {
      const data = await api.getPageData("finish");
      if (data) {
        setState({ ...state, ...data });
      }
    }

    initPage();
    return () => {};
  }, []);

  async function handleProceed() {
    if (await api.postProceed("finish", { configId: state.checkinConfig ? state.checkinConfig.configId : null, customerWrittenComment: state.customerWrittenComment })) {
      window.history.pushState({}, undefined, "/thank-you");
      navigate("/thank-you", { replace: true });
    }
  }

  async function handleCustomerWrittenCommentChange(e) {
    if (e.target.value.length < 990) {
      setState((state) => {
        return { ...state, customerWrittenComment: e.target.value };
      });
    }
  }

  async function handleBack(e) {
    e.preventDefault();
    api.postProceed("back-from-finish", { customerWrittenComment: state.customerWrittenComment });
    navigate(-1);
  }

  return (
    <Flex direction="column" align="center" w="full">
      <Box p={5} width="100%" maxWidth={700} minHeight={"100vh"} borderWidth={1} borderRadius={8} boxShadow="lg">
        <Box textAlign="left">
          <Progress value={90}></Progress>
        </Box>

        <Heading mt={4} textAlign={"left"} size="lg">
          Remember
        </Heading>
        <Text mt="3" textAlign="left">
          You must be at the <b>Portmagee marina</b> <Image display="inline" src={AnchorLogo} alt="Portmagee" /> at <b>{state.checkinConfig ? state.checkinConfig.formattedArrivalTime : ""}</b>
        </Text>

        <Text mt={3} textAlign={"left"}>
          Ask there for <b>Brendan Casey</b>.
        </Text>

        <Text mt={3} textAlign={"left"}>
          We will contact you immediately if there are any updates before then, so please keep an eye on your phone and emails.
        </Text>

        <Text mt={4} textAlign={"left"}>
          <b>Comments (Optional):</b>
        </Text>
        <Textarea
          mt={1}
          rows={4}
          value={state.customerWrittenComment}
          onChange={handleCustomerWrittenCommentChange}
          placeholder="Here you can write any additional info or comments that you would like us to know."
        />

        <Text mt={4} textAlign={"left"}>
          <b>Checklist:</b>
        </Text>

        <UnorderedList mt={0} textAlign={"left"}>
          <ListItem>Good walking shoes (not flat soles)</ListItem>
          <ListItem>Warm comfortable clothing</ListItem>
          <ListItem>Waterproofs</ListItem>
          <ListItem>Drinking water</ListItem>
          <ListItem>Packed lunch</ListItem>
          <ListItem>Camera :)</ListItem>
        </UnorderedList>
        <Text mt={3} textAlign={"left"}>
          If you think you might suffer motion sickness, consider taking medication before the tour and be sure to only eat a light breakfast.
        </Text>
        <Text mt={3} textAlign={"left"}>
          Click below to complete your check-in. You will also receive an email with confirmation.
        </Text>

        {api.errorMessage && (
          <Text minHeight="10" color="red.500">
            {api.errorMessage}
          </Text>
        )}
        <Divider mt={3} />

        <Flex justifyContent="space-between" my="3">
          <Button isLoading={api.isLoading} onClick={handleBack} type="submit" variant="solid" colorScheme="gray">
            Back
          </Button>
          <Button isLoading={api.isLoading} data-accept-not-contactable={false} onClick={handleProceed} type="submit" variant="solid" colorScheme="green">
            Complete Check-in
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
}

export default Finish;
