import { FormControl, InputGroup, InputLeftElement, Stack, Input, Flex, Divider, Button, Box, Heading, FormErrorMessage, FormLabel, Text, FormHelperText } from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { EmailIcon, CalendarIcon } from "@chakra-ui/icons";
import { useApi } from "../../../hooks/customer/useApi";
import { authContext } from "../../../contexts/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";

function Landing() {
  const api = useApi();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [state, setState] = useState({ isAutoCheckinOn: true });

  const auth = useContext(authContext);

  useEffect(() => {
    async function initPage() {
      const data = await api.getPageData("landing");
      if (data && data.isAutoCheckinOn) {
        let url = "/login";
        const currentBookingNumberParam = searchParams.get("bookingNumber");
        const mlParam = searchParams.get("ml");
        if (mlParam) {
          url += "?ml=" + mlParam;
        } else if (currentBookingNumberParam) {
          url += "?bookingNumber=" + currentBookingNumberParam;
        }
        window.history.pushState({}, undefined, url);
        navigate(url, { replace: true });
      } else {
        setState((state) => {
          return {
            ...state,
            isAutoCheckinOn: data.isAutoCheckinOn,
          };
        });
      }
    }

    initPage();
    return () => {};
  }, []);

  return (
    <Flex direction="column" align="center" w="full">
      {!state.isAutoCheckinOn && (
        <Box p={8} width="100%" maxWidth={700} minHeight={"100vh"} borderWidth={1} borderRadius={8} boxShadow="lg">
          <Box mt={6} textAlign="center">
            <Heading>Check-in for Skellig Michael Landing Tour</Heading>
          </Box>
          <Box mt={6} textAlign="center">
            <Text style={{ fontWeight: "bold", fontSize: 18 }}>Online check-in is not available at the moment</Text>
          </Box>
          <Box mt={6} textAlign="center">
            <Text style={{ fontSize: 24 }}>You can check-in by Phone Call / SMS / WhatsApp on:</Text>
            <Text style={{ fontWeight: "bold", fontSize: 24 }}>+353 87 2741537</Text>
            <Text style={{ fontSize: 24 }}>(between 12pm and 4pm the day before your tour)</Text>
          </Box>
          <Box mt={6} textAlign="center">
            <Text style={{ fontSize: 20, fontStyle: "italic" }}>
              If you are sending a message, include the name on your booking, number of people booked and where you are staying the night before the tour
            </Text>
          </Box>
          <Box mt={6} textAlign="center">
            <Text style={{ fontSize: 18, fontWeight: "bold" }}>
              We will reply to your message or call you to confirm your check-in and to update you on the sea conditions and departure time for your tour. We may not reply immediately because we wait
              until we have the most up to date sea/weather forecast information before getting back to you{" "}
            </Text>
          </Box>
        </Box>
      )}
    </Flex>
  );
}

export default Landing;
