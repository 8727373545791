import React, { useEffect, useState } from "react";
import {
  Accordion,
  HStack,
  AccordionItem,
  AccordionButton,
  Menu,
  AccordionPanel,
  MenuItemOption,
  Flex,
  Divider,
  Button,
  Box,
  Heading,
  Tag,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  MenuButton,
  MenuList,
  MenuItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  AccordionIcon,
  Grid,
  GridItem,
  SimpleGrid,
  TagLabel,
  TagRightIcon,
  TagLeftIcon,
  Link,
} from "@chakra-ui/react";
import {
  ArrowForwardIcon,
  Icon,
  ChatIcon,
  LinkIcon,
  ChevronDownIcon,
  MoonIcon,
  PhoneIcon,
  EmailIcon,
  SettingsIcon,
  ArrowRightIcon,
  CalendarIcon,
  CheckCircleIcon,
  ArrowBackIcon,
} from "@chakra-ui/icons";
import { CgProfile } from "react-icons/cg";
import { useApi } from "../../../hooks/internal/useApi";
// import {authContext} from "../contexts/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";

function EventOverview() {
  const [searchParams, setSearchParams] = useSearchParams();
  // const authContext = useContext(authContext);
  const api = useApi();
  const navigate = useNavigate();

  const [state, setState] = useState({
    data: [],
  });

  async function initPage() {
    const startTimeParam = searchParams.get("startTime");
    const productIdParam = searchParams.get("productId");
    console.log("startTimeParam", startTimeParam);
    const data = await api.getPageData("event-overview", [
      { name: "startTime", value: encodeURIComponent(startTimeParam) },
      { name: "productId", value: productIdParam },
    ]);
    if (data) {
      console.log("data", data);
      setState(() => {
        return {
          ...state,
          data: data,
        };
      });
    }
  }

  useEffect(() => {
    api.checkAuth();
    initPage();
    return () => {};
  }, []);

  async function handleBoatChosen(eventId, bookingNumber, boatId) {
    console.log("eventId", eventId);
    console.log("bookingNumber", bookingNumber);
    console.log("boatId", boatId);

    api.postProceed("assign-boat-to-booking", { eventId, bookingNumber, boatId });

    setState((prevState) => {
      const newData = prevState.data.map((event) => {
        // 👇️ if id equals 2, update country property
        let numKenBrencent = 0;
        let numSkelligCrest = 0;
        let numSkelligVenture = 0;
        const bookings = event.bookings.map((booking) => {
          const updatedBooking = { ...booking };
          if (booking.bookingNumber === bookingNumber && booking.eventId === eventId) {
            updatedBooking.boatId = boatId;
          }
          if (updatedBooking.boatId === "KB") numKenBrencent += updatedBooking.numberOfPassengers;
          if (updatedBooking.boatId === "SC") numSkelligCrest += updatedBooking.numberOfPassengers;
          if (updatedBooking.boatId === "SV") numSkelligVenture += updatedBooking.numberOfPassengers;

          return updatedBooking;
        });
        return { ...event, numKenBrencent, numSkelligCrest, numSkelligVenture, bookings: bookings };
      });

      return { ...prevState, data: newData };
    });
  }

  function handleArrowBackIconClicked() {
    navigate(-1);
  }

  async function handleBookingTitleClicked(bookingNumber, emailAddress) {
    const magicCode = await api.getPageData("booking-ml", [
      { name: "bookingNumber", value: bookingNumber },
      { name: "emailAddress", value: emailAddress },
    ]);
    console.log(magicCode);
    if (magicCode) {
      const url = `/login?ml=${magicCode}`;
      setTimeout(() => {
        window.open(url, "_blank");
      });
    }
  }

  async function handleContactExportClicked() {
    const startTimeParam = searchParams.get("startTime");
    const productIdParam = searchParams.get("productId");
    const url = `/internal/contacts-export?startTime=${encodeURIComponent(startTimeParam)}&productId=${productIdParam}`;
    window.history.pushState({}, undefined, url);
    navigate(url, { replace: true });
  }

  function getMainPhoneIconColor(booking) {
    if (!booking.existingNumberFormatted) {
      return "red.500";
    }
    if (booking.isOriginalNumberContactable === false) {
      return "red.500";
    }
    if (booking.isOriginalNumberContactable) {
      return "green.500";
    }
    return "";
  }

  function getMainPhoneNumberColor(booking) {
    if (!booking.existingNumberFormatted) {
      return "red.500";
    }
    if (booking.isOriginalNumberContactable === false) {
      return "red.500";
    }
    return "teal.500";
  }

  function getMainAlternativePhoneIconColor(booking) {
    if (!booking.existingAlternativeNumberFormatted) {
      return "red.500";
    }
    if (booking.isOriginalAlternativeNumberContactable === false) {
      return "red.500";
    }
    if (booking.isOriginalAlternativeNumberContactable) {
      return "green.500";
    }
    return "";
  }

  function getMainAlternativePhoneNumberColor(booking) {
    if (!booking.existingAlternativeNumberFormatted) {
      return "red.500";
    }
    if (booking.isOriginalAlternativeNumberContactable === false) {
      return "red.500";
    }
    return "teal.500";
  }

  const boatColorButtonMap = {
    KB: "blue.100",
    SC: "yellow.100",
    SV: "purple.100",
    undefined: "white",
  };

  const boatColorBackgroundMap = {
    KB: "blue.50",
    SC: "yellow.50",
    SV: "purple.50",
    undefined: "white",
    "": "white",
  };

  const boatIdToNameMapping = {
    KB: "Kenbrencent",
    SC: "Skellig Crest",
    SV: "Skellig Venture",
    undefined: "Arrived?",
    "": "Arrived?",
  };

  function generatePhoneNumberDisplay(booking) {
    const phoneNumberDisplays = [];

    if (booking.existingNumberFormatted) {
      phoneNumberDisplays.push({
        iconColor: getMainPhoneIconColor(booking),
        number: booking.existingNumberFormatted,
        color: getMainPhoneNumberColor(booking),
      });
    }

    if (booking.existingAlternativeNumberFormatted) {
      phoneNumberDisplays.push({
        iconColor: getMainAlternativePhoneIconColor(booking),
        number: booking.existingAlternativeNumberFormatted,
        color: getMainAlternativePhoneNumberColor(booking),
      });
    }

    if (booking.formattedAdditionalNumber1) {
      phoneNumberDisplays.push({
        iconColor: "",
        number: booking.formattedAdditionalNumber1,
        color: "teal.500",
      });
    }

    if (booking.formattedAdditionalNumber2) {
      phoneNumberDisplays.push({
        iconColor: "",
        number: booking.formattedAdditionalNumber2,
        color: "teal.500",
      });
    }

    if (booking.formattedAdditionalNumber3) {
      phoneNumberDisplays.push({
        iconColor: "",
        number: booking.formattedAdditionalNumber3,
        color: "teal.500",
      });
    }

    const phoneNumbersDom = [];
    for (let i = 0; i < phoneNumberDisplays.length; i += 2) {
      phoneNumbersDom.push(
        <Flex justifyContent={"space-between"} key={i}>
          <Text fontSize={14} overflowWrap={"break-word"} maxWidth={"65%"}>
            <PhoneIcon w={3} h={3} color={phoneNumberDisplays[i].iconColor}></PhoneIcon>
            <Link ml={1} href={`tel:${phoneNumberDisplays[i].number}`} color={phoneNumberDisplays[i].color}>
              {phoneNumberDisplays[i].number}
            </Link>
          </Text>
          {phoneNumberDisplays[i + 1] && (
            <Text fontSize={14} overflowWrap={"break-word"} maxWidth={"65%"}>
              <PhoneIcon w={3} h={3} color={phoneNumberDisplays[i + 1].iconColor}></PhoneIcon>
              <Link ml={1} href={`tel:${phoneNumberDisplays[i + 1].number}`} color={phoneNumberDisplays[i + 1].color}>
                {phoneNumberDisplays[i + 1].number}
              </Link>
            </Text>
          )}
        </Flex>
      );
    }

    return phoneNumbersDom;
  }

  return (
    <Flex direction="column" align="center" w="full" minHeight={"100vh"}>
      {state.data.map((event) => {
        return (
          <Box key={event.eventId} py={2} px={2} width={"100%"} maxWidth={800} borderWidth={1} borderRadius={8} boxShadow="lg" textAlign="left">
            <Heading size="lg"></Heading>
            <Flex alignContent={"flex-start"}>
              <ArrowBackIcon boxSize={"35px"} cursor={"pointer"} onClick={handleArrowBackIconClicked}></ArrowBackIcon>
              <Heading size="lg" pl={2}>
                {event.productName}
              </Heading>
              <LinkIcon marginLeft={"auto"} alignSelf={"center"} onClick={handleContactExportClicked} cursor={"pointer"}></LinkIcon>
            </Flex>

            <Box maxWidth={"100%"}>
              <Text mt="1" mb="2" fontWeight={"bold"} fontSize="19px">
                {event.formattedStartTime}
              </Text>

              <Box my={2}>
                <Flex justifyContent={"space-between"}>
                  <Text fontSize={"19px"}>
                    {event.totalPassengers} {event.totalPassengers > 1 ? "Passengers" : "Passenger"}
                  </Text>
                  {event.numberFullyCheckedIn > 0 && (
                    <Tag size={"sm"} variant="outline" colorScheme={event.numberFullyCheckedIn === event.bookings.length ? "green" : "gray"}>
                      <TagLeftIcon as={CalendarIcon} />
                      <TagLabel>
                        {event.numberFullyCheckedIn}/{event.bookings.length}
                      </TagLabel>
                    </Tag>
                  )}
                </Flex>
              </Box>

              <Flex width={"100%"} my={2} minHeight={"25px"} justifyContent={"space-between"}>
                <Tag size={"sm"} variant="outline" colorScheme="blue" maxHeight={"20px"}>
                  <TagLabel>
                    {event.numKenBrencent + event.numSkelligCrest + event.numSkelligVenture}/{event.totalPassengers}
                  </TagLabel>
                  <TagRightIcon as={CheckCircleIcon} />
                </Tag>
                <Flex justifyContent={"flex-end"} flexWrap={"wrap"} maxWidth={"80%"}>
                  {event.numKenBrencent > 0 && (
                    <Tag size={"sm"} mb={"3px"} ml={"3px"} variant="outline" color={"blue.900"} backgroundColor={"blue.100"}>
                      <TagLabel>{event.numKenBrencent} Kenbrencent</TagLabel>
                    </Tag>
                  )}
                  {event.numSkelligCrest > 0 && (
                    <Tag size={"sm"} mb={"3px"} ml={"3px"} variant="outline" color={"yellow.900"} backgroundColor={"yellow.100"}>
                      <TagLabel>{event.numSkelligCrest} Skellig Crest</TagLabel>
                    </Tag>
                  )}
                  {event.numSkelligVenture > 0 && (
                    <Tag size={"sm"} mb={"3px"} ml={"3px"} variant="outline" color={"purple.900"} backgroundColor={"purple.100"}>
                      <TagLabel>{event.numSkelligVenture} Skellig Venture</TagLabel>
                    </Tag>
                  )}
                </Flex>
              </Flex>

              {event.bookings.map((booking) => {
                return (
                  <Box key={booking.bookingNumber} mt={2} borderRadius="5px" p={1} boxShadow={"1px 1px 2px 1px lightgrey"} backgroundColor={boatColorBackgroundMap[booking.boatId]}>
                    <Flex justifyContent={"space-between"}>
                      <Text fontSize={"17px"}>
                        <Button
                          variant={"link"}
                          color={"black"}
                          fontWeight={"normal"}
                          onClick={() => {
                            handleBookingTitleClicked(booking.bookingNumber, booking.emailAddress);
                          }}
                        >
                          {booking.title}
                        </Button>
                      </Text>
                      <Menu>
                        {({ isOpen }) => (
                          <>
                            <MenuButton
                              isActive={isOpen}
                              as={Button}
                              height={"25px"}
                              size={"sm"}
                              border="1px"
                              color={"gray"}
                              backgroundColor={boatColorButtonMap[booking.boatId]}
                              rightIcon={<ArrowRightIcon />}
                              mt={1}
                            >
                              {/* {isOpen ? "Skellig Venture" : "Arrived?"} */}
                              {boatIdToNameMapping[booking.boatId]}
                            </MenuButton>
                            <MenuList fontSize={12}>
                              <MenuItem onClick={() => handleBoatChosen(event.eventId, booking.bookingNumber, "")}>None</MenuItem>
                              <MenuItem color={"blue.900"} backgroundColor={"blue.50"} onClick={() => handleBoatChosen(event.eventId, booking.bookingNumber, "KB")}>
                                Kenbrencent
                              </MenuItem>
                              <MenuItem color={"yellow.900"} backgroundColor={"yellow.50"} onClick={() => handleBoatChosen(event.eventId, booking.bookingNumber, "SC")}>
                                Skellig Crest
                              </MenuItem>
                              <MenuItem color={"purple.900"} backgroundColor={"purple.50"} onClick={() => handleBoatChosen(event.eventId, booking.bookingNumber, "SV")}>
                                Skellig Venture
                              </MenuItem>
                            </MenuList>
                          </>
                        )}
                      </Menu>
                    </Flex>
                    <Flex width={"100%"}>
                      {/* <Text fontSize={15} pt={"1px"} fontWeight={"bold"}>
                        
                      </Text> */}
                      <Flex width={"60%"}>
                        <Box ml={0} mt={1} mr={1}>
                          <CgProfile></CgProfile>
                        </Box>
                        <Text fontSize={15} fontWeight="bold" overflowWrap={"break-word"}>
                          {booking.participantsString}
                        </Text>
                      </Flex>
                      {booking.mostRecentStepName && (
                        <HStack spacing={4} mt={1} justifyContent={"flex-end"} width={"40%"}>
                          <Tag size={"sm"} variant="outline" colorScheme={booking.isCheckedIn ? "green" : "blue"}>
                            <TagLeftIcon as={CalendarIcon} />
                            <TagLabel>{booking.mostRecentStepName}</TagLabel>
                          </Tag>
                        </HStack>
                      )}
                    </Flex>
                    <Flex justifyContent={"space-between"} mt={1}>
                      <Text fontSize={12} overflowWrap={"break-word"} maxWidth={"65%"}>
                        <EmailIcon w={3} h={3} mr={2}></EmailIcon>
                        <span>{booking.emailAddress}</span>
                      </Text>
                      <Text fontSize={12} alignSelf={"flex-end"}>
                        {booking.bookingNumber}
                      </Text>
                    </Flex>
                    {generatePhoneNumberDisplay(booking)}
                    {booking.comingFromLocationFormatted && (
                      <Flex mt={1}>
                        <Text fontSize={12} overflowWrap={"break-word"}>
                          <MoonIcon></MoonIcon>
                        </Text>
                        <Text fontSize={12} overflowWrap={"break-word"} pl={2}>
                          {booking.comingFromLocationFormatted}
                        </Text>
                      </Flex>
                    )}
                    {booking.customerWrittenComment && (
                      <Flex mt={1}>
                        <Text fontSize={12} overflowWrap={"break-word"}>
                          <ChatIcon></ChatIcon>
                        </Text>
                        <Text fontSize={12} overflowWrap={"break-word"} pl={2}>
                          {booking.customerWrittenComment}
                        </Text>
                      </Flex>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}

      {api.errorMessage && (
        <Text mt={2} color="red.500">
          {api.errorMessage}
        </Text>
      )}
    </Flex>
  );
}

export default EventOverview;
