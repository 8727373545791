import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Menu,
  AccordionPanel,
  MenuItemOption,
  Flex,
  Divider,
  Button,
  Box,
  Heading,
  Tag,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  MenuButton,
  MenuList,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  AccordionIcon,
} from "@chakra-ui/react";
import { ArrowForwardIcon, ChevronDownIcon, ArrowBackIcon } from "@chakra-ui/icons";
import { useApi } from "../../../hooks/internal/useApi";
// import {authContext} from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

function CheckinConfiguration() {
  // const authContext = useContext(authContext);
  const api = useApi();
  const navigate = useNavigate();

  const [config, setConfig] = useState({
    isAutoCheckinOn: true,
    departureHour: 8,
    departureMin: 30,
    hasWindRisk: false,
    hasSwellRisk: false,
    hasRainRisk: false,
    isCancellationLikely: false,
    cancellationFromZone: 0,
    askEcoPotential: false,
  });
  const [allConfigs, setAllConfigs] = useState([]);
  const [paginationIndex, setPaginationIndex] = useState(1);

  async function initPage() {
    const data = await api.getPageData("checkin-configuration");
    if (data && data.checkinConfigs && data.checkinConfigs.length > 0) {
      setConfig(data.checkinConfigs[0]);
      setAllConfigs(data.checkinConfigs);
    }
  }

  useEffect(() => {
    api.checkAuth();
    initPage();
    return () => {};
  }, []);

  async function handleSaveConfig(e) {
    e.preventDefault();
    if (await api.postProceed("checkin-configuration", config)) {
      // history.push("/internal/checkin-configuration");
      initPage();
    }
  }

  function handleBooleanButton(e) {
    e.preventDefault();
    console.log("Button Clicked", e.target.dataset.name);
    setConfig((oldConfig) => {
      return { ...oldConfig, [e.target.dataset.name]: !oldConfig[e.target.dataset.name] };
    });
  }

  function handleCancellationFromZoneClicked(zone) {
    console.log(zone);
    setConfig((oldConfig) => {
      return { ...oldConfig, cancellationFromZone: zone };
    });
  }

  function handleHourChange(str, valueAsNumber) {
    if (str.length > 0) {
      setConfig((oldConfig) => {
        return { ...oldConfig, departureHour: valueAsNumber };
      });
    }
  }

  function handleMinChange(str, valueAsNumber) {
    if (str.length > 0) {
      setConfig((oldConfig) => {
        return { ...oldConfig, departureMin: valueAsNumber };
      });
    }
  }

  function showShowMoreConfigs() {
    console.log("Show more configs");
    return allConfigs.length > paginationIndex * 20;
  }

  function handleShowMoreConfigs() {
    setPaginationIndex((oldIndex) => {
      return oldIndex + 1;
    });
  }

  function handleArrowBackIconClicked() {
    navigate(-1);
  }

  function renderAllConfigs() {
    let allConfigDisplay = [];
    // <div key={config.id}>
    // {config.id} {config.departureHour}
    // </div>
    const paginatedConfigs = allConfigs.slice(0, paginationIndex * 20);
    let i = 0;
    for (const config of paginatedConfigs) {
      // console.log(config);

      allConfigDisplay.push(
        <AccordionItem key={config.id} backgroundColor={i === 0 ? "green.100" : ""}>
          <h2>
            <AccordionButton py={0}>
              <Box flex={1} textAlign="left">
                #{config.id}
              </Box>
              <Box flex={1} textAlign="left">
                {config.departureHour}:{config.departureMin}
              </Box>
              <Box flex={4} textAlign="left" fontWeight="bold" color="red.500">
                {!config.isAutoCheckinOn && "Checkin Off"}
                {config.isAutoCheckinOn && (
                  <>
                    {config.hasWindRisk && "W "}
                    {config.hasSwellRisk && "S "}
                    {config.hasRainRisk && "R "}
                    {config.isCancellationLikely && "CL "}
                    {config.askEcoPotential && "Eco "}
                    {config.cancellationFromZone !== 0 && `Zone ${config.cancellationFromZone}`}
                  </>
                )}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel py={2} pt={0} display="flex" flexWrap="wrap">
            {!config.isAutoCheckinOn && (
              <Tag size="md" variant="solid" colorScheme="orange" mr={1} mt={1}>
                Checkin Off
              </Tag>
            )}
            {config.hasWindRisk && (
              <Tag size="md" variant="solid" colorScheme="orange" mr={1} mt={1}>
                Wind Risk
              </Tag>
            )}
            {config.hasSwellRisk && (
              <Tag size="md" variant="solid" colorScheme="orange" mr={1} mt={1}>
                Swell Risk
              </Tag>
            )}
            {config.hasRainRisk && (
              <Tag size="md" variant="solid" colorScheme="orange" mr={1} mt={1}>
                Rain Risk
              </Tag>
            )}
            {config.isCancellationLikely && (
              <Tag size="md" variant="solid" colorScheme="orange" mr={1} mt={1}>
                Cancellation Likely
              </Tag>
            )}
            {config.askEcoPotential && (
              <Tag size="md" variant="solid" colorScheme="orange" mr={1} mt={1}>
                Ask Eco Potential
              </Tag>
            )}
            {config.cancellationFromZone !== 0 && (
              <Tag size="md" variant="solid" colorScheme="orange" mr={1} mt={1}>
                Zone {config.cancellationFromZone}
              </Tag>
            )}
            <Text width="100%" mt={2}>
              {config.createdBy}
            </Text>
            <Text width="100%">{config.createdAt} </Text>
          </AccordionPanel>
        </AccordionItem>
      );
      i += 1;
    }
    return allConfigDisplay;
  }

  return (
    <Flex direction="column" align="center" w="full" minHeight={"100vh"}>
      <Box p={3} width={"100%"} maxWidth={800} borderWidth={1} borderRadius={8} boxShadow="lg" textAlign="left">
        <Flex alignContent={"flex-start"}>
          <ArrowBackIcon boxSize={"35px"} cursor={"pointer"} onClick={handleArrowBackIconClicked}></ArrowBackIcon>
          <Heading size="lg" pl={2}>
            Checkin Configuratation
          </Heading>
        </Flex>
        <Text my="5">Override the current config below.</Text>
        <Divider></Divider>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th paddingLeft={1} width="50%" textAlign={"left"}>
                <b>Condition</b>
              </Th>
              <Th textAlign={"right"}>
                <b>Setting</b>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td py={1} px={0}>
                Checkin On?
              </Td>
              <Td py={1} px={0}>
                <Flex justifyContent={"flex-end"}>
                  <Button minWidth={110} onClick={handleBooleanButton} data-name="isAutoCheckinOn" colorScheme={config.isAutoCheckinOn ? "green" : "orange"} size="sm">
                    {config.isAutoCheckinOn ? "Yes, On" : "No, Off"}
                  </Button>
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td py={1} px={0}>
                Departure Time
              </Td>
              <Td py={1} px={0}>
                <Flex justifyContent={"flex-end"}>
                  <NumberInput min={0} max={23} onChange={handleHourChange} value={config.departureHour}>
                    <NumberInputField paddingLeft="0.25rem" maxWidth="60px" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <NumberInput ml={2} min={0} max={59} onChange={handleMinChange} value={config.departureMin}>
                    <NumberInputField paddingLeft="0.25rem" maxWidth="60px" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td py={1} px={0}>
                Wind risk?
              </Td>
              <Td py={1} px={0}>
                <Flex justifyContent={"flex-end"}>
                  <Button minWidth={110} onClick={handleBooleanButton} data-name="hasWindRisk" colorScheme={config.hasWindRisk ? "orange" : "green"} size="sm">
                    {config.hasWindRisk ? "Yes, wind risk" : "No wind risk"}
                  </Button>
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td py={1} px={0}>
                Swell risk?
              </Td>
              <Td py={1} px={0}>
                <Flex justifyContent={"flex-end"}>
                  <Button minWidth={110} onClick={handleBooleanButton} data-name="hasSwellRisk" colorScheme={config.hasSwellRisk ? "orange" : "green"} size="sm">
                    {config.hasSwellRisk ? "Yes, swell risk" : "No swell risk"}
                  </Button>
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td py={1} px={0}>
                Rain risk?
              </Td>
              <Td py={1} px={0}>
                <Flex justifyContent={"flex-end"}>
                  <Button minWidth={110} onClick={handleBooleanButton} data-name="hasRainRisk" colorScheme={config.hasRainRisk ? "orange" : "green"} size="sm">
                    {config.hasRainRisk ? "Yes, rain risk" : "No rain risk"}
                  </Button>
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Td py={1} px={0}>
                Cancellation likely?
              </Td>
              <Td py={1} px={0}>
                <Flex justifyContent={"flex-end"}>
                  <Button minWidth={110} onClick={handleBooleanButton} data-name="isCancellationLikely" colorScheme={config.isCancellationLikely ? "orange" : "green"} size="sm">
                    {config.isCancellationLikely ? "Likely" : "Unlikely"}
                  </Button>
                </Flex>
              </Td>
            </Tr>
            {/*   <Tr>
               <Td py={1} px={0}>
                Ask about Eco Tour?
              </Td>
              <Flex justifyContent={"flex-end"}>
                <Td py={1} px={0}>
                  <Button minWidth={110} onClick={handleBooleanButton} data-name="askEcoPotential" colorScheme={config.askEcoPotential ? "green" : "orange"} size="sm">
                    {config.askEcoPotential ? "Yes" : "No"}
                  </Button>
                </Td>
              </Flex>
            </Tr> */}
            {/* <Tr>
              <Td py={1} px={0}>
                Offer Cancellation?
              </Td>
              <Flex justifyContent={"flex-end"}>
                <Td py={1} px={0}>
                  <Menu autoSelect={false}>
                    <MenuButton as={Button} size="md" minWidth={140} rightIcon={<ChevronDownIcon />}>
                      {config.cancellationFromZone === 0 ? "No" : `From Zone ${config.cancellationFromZone}`}
                    </MenuButton>
                    <MenuList>
                      <MenuItemOption
                        onClick={() => {
                          handleCancellationFromZoneClicked(0);
                        }}
                        isChecked={config.cancellationFromZone === 0}
                      >
                        <b>Not offering</b>
                      </MenuItemOption>
                      <MenuItemOption
                        onClick={() => {
                          handleCancellationFromZoneClicked(1);
                        }}
                        isChecked={config.cancellationFromZone !== 0 && config.cancellationFromZone <= 1}
                      >
                        <div>
                          <b>From Zone 1 (10 mins) </b>
                        </div>
                        <div> Near or in Portmagee</div>
                      </MenuItemOption>
                      <MenuItemOption
                        onClick={() => {
                          handleCancellationFromZoneClicked(2);
                        }}
                        isChecked={config.cancellationFromZone !== 0 && config.cancellationFromZone <= 2}
                      >
                        <div>
                          <b>From Zone 2 (30 mins) </b>
                        </div>
                        <div>Cahersiveen, Kells, Ballinskelligs,</div>
                        <div>Waterville, Dromid</div>
                      </MenuItemOption>
                      <MenuItemOption
                        onClick={() => {
                          handleCancellationFromZoneClicked(3);
                        }}
                        isChecked={config.cancellationFromZone !== 0 && config.cancellationFromZone <= 3}
                      >
                        <div>
                          <b>From Zone 3 (1hr)</b>
                        </div>{" "}
                        <div>Glenbeigh, Kilorglin, Caherdaniel, </div> <div>Castlecove, Sneem </div>
                      </MenuItemOption>
                      <MenuItemOption
                        onClick={() => {
                          handleCancellationFromZoneClicked(4);
                        }}
                        isChecked={config.cancellationFromZone !== 0 && config.cancellationFromZone <= 4}
                      >
                        <div>
                          <b>From Zone 4 (1hr 30mins)</b>
                        </div>
                        <div>Killarney, Tralee, Kenmare</div>
                      </MenuItemOption>
                      <MenuItemOption
                        onClick={() => {
                          handleCancellationFromZoneClicked(5);
                        }}
                        isChecked={config.cancellationFromZone !== 0 && config.cancellationFromZone <= 5}
                      >
                        <div>
                          <b>From Zone 5 (2hrs 15mins)</b>{" "}
                        </div>
                        <div>Dingle, Ballybunion, Rest of Kerry, </div>
                        <div>Parts of Cork and Limerick</div>
                      </MenuItemOption>
                      <MenuItemOption
                        onClick={() => {
                          handleCancellationFromZoneClicked(6);
                        }}
                        isChecked={config.cancellationFromZone !== 0 && config.cancellationFromZone <= 6}
                      >
                        <div>
                          <b> From Zone 6 (3hrs 30mins)</b>
                        </div>{" "}
                        <div> Cork, Limerick, Tipperary, </div>
                        <div>Clare, Parts of Waterford (Dungarvan)</div>
                      </MenuItemOption>
                      <MenuItemOption
                        onClick={() => {
                          handleCancellationFromZoneClicked(7);
                        }}
                        isChecked={config.cancellationFromZone !== 0 && config.cancellationFromZone <= 7}
                      >
                        <div>
                          <b>From Zone 7 (7hrs)</b> Rest of Ireland
                        </div>
                      </MenuItemOption>
                      <MenuItemOption
                        onClick={() => {
                          handleCancellationFromZoneClicked(8);
                        }}
                        isChecked={config.cancellationFromZone !== 0 && config.cancellationFromZone <= 8}
                      >
                        <div>
                          <b>From Zone 8 (More than 7hrs)</b>
                        </div>
                        <div> Extreme Journey</div>
                      </MenuItemOption>
                    </MenuList>
                  </Menu>
                </Td>
              </Flex>
            </Tr> */}
          </Tbody>
        </Table>

        {api.errorMessage && (
          <Text mt={2} color="red.500">
            {api.errorMessage}
          </Text>
        )}
        <Flex justifyContent="flex-end">
          <Button my="2" isLoading={api.isLoading} onClick={handleSaveConfig} type="submit" variant="solid" colorScheme="messenger">
            Save Config
          </Button>
        </Flex>
      </Box>
      <Box p={1} mt={2} width="100%" maxWidth={800} borderWidth={1} borderRadius={8} boxShadow="lg" textAlign="left">
        <Heading m={2} as="h4" size="md">
          All Configuratations
        </Heading>
        <Accordion defaultIndex={[0]} allowMultiple>
          {renderAllConfigs()}
        </Accordion>
        {showShowMoreConfigs() && (
          <Flex justifyContent="center">
            <Button my="2" size="sm" onClick={handleShowMoreConfigs} variant="solid">
              Show More
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  );
}

export default CheckinConfiguration;
