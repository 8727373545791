import React, { useEffect, useState } from "react";
import { Flex, Divider, Button, Box, Heading, Text, Progress } from "@chakra-ui/react";
import { useApi } from "../../../hooks/customer/useApi";
// import {authContext} from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

function CheckIn() {
  // const authContext = useContext(authContext);
  const [state, setState] = useState({});
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    api.checkAuth();
    async function initPage() {
      const data = await api.getPageData("welcome-page");
      if (data) {
        setState(data);
      }
    }

    initPage();
    return () => {};
  }, []);

  async function handleProceed() {
    if (await api.postProceed("welcome-page", { configId: state.configId })) {
      window.history.pushState({}, undefined, "/sea-weather-conditions");
      navigate("/sea-weather-conditions", { replace: true });
    }
  }

  async function handleBack() {
    navigate(-1);
  }

  return (
    <Flex direction="column" align="center" w="full">
      <Box p={5} width="100%" maxWidth={700} minHeight={"100vh"} borderWidth={1} borderRadius={8} boxShadow="lg">
        <Box textAlign="left">
          <Progress value={10}></Progress>
          <Heading size="lg" mt="4">
            Check-in Portal
          </Heading>
          <Text my="5">
            Hi {state.firstName}, welcome to the check-in portal for your <b>{state.productName}</b>.
          </Text>
          <Divider></Divider>
          <Box my="3">
            <Text fontWeight="bold">Name:</Text>
            <Text>{state.title}</Text>
          </Box>
          <Box my="3">
            <Text fontWeight="bold">Participants:</Text>
            <Text>{state.numPassengers} Passengers</Text>
          </Box>
          <Box my="3">
            <Text fontWeight="bold">Booking Number:</Text>
            <Text>{state.bookingNumber}</Text>
          </Box>
        </Box>
        <Divider />

        {api.errorMessage && (
          <Text minHeight="10" color="red.500">
            {api.errorMessage}
          </Text>
        )}
        <Divider />
        {/* {state.step_completed_at && (
          <Text minHeight="10" color="teal.500">
            Step already confirmed at {state.step_completed_at}
          </Text>
        )} */}
        <Flex justifyContent="space-between" my="3">
          <Button isLoading={api.isLoading} onClick={handleBack} type="submit" variant="solid" colorScheme="gray">
            Back
          </Button>
          <Button isLoading={api.isLoading} onClick={handleProceed} type="submit" variant="solid" colorScheme="green">
            Next
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
}

export default CheckIn;
