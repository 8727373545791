import { useState, useContext } from "react";
import { authContext } from "../../contexts/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// const serverUrl = process.env.NODE_ENV === "production" ? "https://checkin.secure.skelligdesign.com" : "http://localhost:8080";
const serverUrl = process.env.NODE_ENV === "production" ? "https://checkin.skelligislands.com" : "http://localhost:8080";

const routeUrl = "/api/customer/";

export const useApi = () => {
  const navigate = useNavigate();

  const auth = useContext(authContext);
  console.log("Auth Context", auth);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setApiErrorMessage] = useState("");

  async function checkAuth() {
    try {
      setIsLoading(true);
      const result = await axios.get(serverUrl + routeUrl + "checkauth", { withCredentials: true });
      setIsLoading(false);
      //   navigate.push("/first-page");

      console.log(result.data);
    } catch (e) {
      setIsLoading(false);
      handleCatchErrors(e);
      console.log(e.message);
    }
  }

  async function login(bookingNumber, emailAddress) {
    try {
      setApiErrorMessage("");
      auth.setError("");
      setIsLoading(true);
      const result = await axios.post(
        serverUrl + routeUrl + "login",
        {
          bookingNumber: bookingNumber,
          emailAddress: emailAddress,
        },
        { withCredentials: true }
      );
      setIsLoading(false);
      if (result.data.errorMessage) {
        console.log("Setting Error");
        setApiErrorMessage(result.data.errorMessage);
        return false;
      } else {
        return true;
      }
    } catch (e) {
      setIsLoading(false);
      setApiErrorMessage(e.message);
      console.log(e.message);
      return false;
    }
  }

  async function mlLogin(magicCode) {
    try {
      setApiErrorMessage("");
      auth.setError("");
      setIsLoading(true);
      const result = await axios.post(
        serverUrl + routeUrl + "ml-login",
        {
          magicCode: magicCode,
        },
        { withCredentials: true }
      );
      setIsLoading(false);
      if (result.data.errorMessage) {
        console.log("Setting Error");
        setApiErrorMessage(result.data.errorMessage);
        return false;
      } else {
        return true;
      }
    } catch (e) {
      setIsLoading(false);
      setApiErrorMessage(e.message);
      console.log(e.message);
      return false;
    }
  }

  function handleCatchErrors(error) {
    if (error.response && error.response.status === 498) {
      console.log(auth);
      // auth.setError("You have been logged out do to inactivity.");
      // auth.setError("You have been logged out do to inactivity.");
      auth.setError(error.response.data.errorMessage);
      console.log(error.response);

      navigate("/login", { replace: true });
    } else if (error.response && error.response.status === 403 && error.response.data.errorMessage === "checked-in") {
      navigate("/thank-you", { replace: true });
    } else {
      setApiErrorMessage(error.message);
    }
    console.log(error.message);
  }

  async function getPageData(pageUri) {
    try {
      setApiErrorMessage("");
      setIsLoading(true);
      const result = await axios.get(serverUrl + routeUrl + pageUri, { withCredentials: true });
      setIsLoading(false);
      if (!handleResultErrors(result)) {
        console.log("Can now display the page");
        return result.data;
      }
      return false;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      handleCatchErrors(error);
      return false;
    }
  }

  async function postProceed(uri, payload) {
    try {
      setApiErrorMessage("");
      setIsLoading(true);
      console.log("payload", payload);
      const result = await axios.post(serverUrl + routeUrl + uri, { payload }, { withCredentials: true });
      setIsLoading(false);
      if (!handleResultErrors(result)) {
        return true;
      }
      return false;
    } catch (error) {
      setIsLoading(false);
      handleCatchErrors(error);
      return false;
    }
  }

  function handleResultErrors(result) {
    if (result.data.errorMessage) {
      console.log("Setting Error");
      setApiErrorMessage(result.data.errorMessage);
      return true;
    }
    return false;
  }

  return {
    checkAuth,
    login,
    mlLogin,
    getPageData,
    postProceed,
    isLoading,
    errorMessage,
  };
};
