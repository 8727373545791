import { FormControl, InputGroup, InputLeftElement, Stack, Input, Flex, Divider, Button, Box, Heading, FormErrorMessage, FormLabel, Text, FormHelperText } from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { LockIcon, StarIcon } from "@chakra-ui/icons";
import { useApi } from "../../../hooks/internal/useApi";
import { authContext } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

function Login() {
  const api = useApi();
  const navigate = useNavigate();

  const auth = useContext(authContext);

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    // api.checkAuth();
    return () => {};
  }, []);

  function handleusernameChange(e) {
    if (usernameError) {
      setUsernameError("");
    }
    setUsername(e.target.value);
  }

  function handleEmailNumberChange(e) {
    if (passwordError) {
      setPasswordError("");
    }
    setPassword(e.target.value);
  }

  function isFormValid() {
    const hasValidUsername = !!username;
    const hasValidPassword = !!password;
    if (!hasValidUsername) {
      setUsernameError("Please input your username.");
    }
    if (!hasValidPassword) {
      setPasswordError("Please input your password.");
    }
    return hasValidUsername && hasValidPassword;
  }

  async function handleProceed(e) {
    e.preventDefault();
    if (isFormValid()) {
      if (await api.postProceed("login", { username, password })) {
        window.history.pushState({}, undefined, "/internal/events-list");
        navigate("/internal/events-list", { replace: true });
      }
    }
  }

  return (
    <Flex direction="column" align="center" w="full" h="100vh">
      <Box p={8} width="100%" maxWidth={500} borderWidth={1} borderRadius={8} boxShadow="lg">
        <Box textAlign="center">
          <Heading>Internal Login</Heading>
        </Box>
        <Box my={10} textAlign="left">
          <form action="submit">
            <Stack spacing="3">
              <FormControl isRequired isInvalid={usernameError}>
                <FormLabel>Username</FormLabel>
                <InputGroup>
                  <InputLeftElement children={<StarIcon />} />
                  <Input onChange={handleusernameChange} value={username} type="email" placeholder="Enter Username" aria-label="Username" />
                </InputGroup>
                <FormHelperText>Your username for the system.</FormHelperText>

                <FormErrorMessage>{usernameError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={passwordError}>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <InputLeftElement children={<LockIcon />} />
                  <Input onChange={handleEmailNumberChange} value={password} type="password" placeholder="Enter Password" aria-label="Password" />
                </InputGroup>
                <FormHelperText>The password associated with your user account.</FormHelperText>
                <FormErrorMessage>{passwordError}</FormErrorMessage>
              </FormControl>
              <Divider />
              {auth.error && (
                <Text minHeight="10" color="red.500">
                  {auth.error}
                </Text>
              )}
              {api.errorMessage && (
                <Text minHeight="10" color="red.500">
                  {api.errorMessage}
                </Text>
              )}
              <Divider />
              <Button isLoading={api.isLoading} onClick={handleProceed} type="submit" variant="solid" colorScheme="green">
                Login
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </Flex>
  );
}

export default Login;
