import { useState, useContext } from "react";
import { authContext } from "../../contexts/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const serverUrl = process.env.NODE_ENV === "production" ? "https://checkin.skelligislands.com" : "http://localhost:8080";
const routeUrl = "/api/internal/";

export const useApi = () => {
  const navigate = useNavigate();

  const auth = useContext(authContext);
  console.log("Auth Context", auth);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setApiErrorMessage] = useState("");

  async function checkAuth() {
    try {
      setIsLoading(true);
      const result = await axios.get(serverUrl + routeUrl + "checkauth", { withCredentials: true });
      setIsLoading(false);
      //   history.push("/first-page");

      console.log(result.data);
    } catch (e) {
      setIsLoading(false);
      handleCatchErrors(e);
      console.log(e.message);
    }
  }

  function handleCatchErrors(error) {
    if (error.response && error.response.status === 498) {
      console.log(auth);
      // auth.setError("You have been logged out do to inactivity.");
      // auth.setError("You have been logged out do to inactivity.");
      auth.setError(error.response.data.errorMessage);
      console.log(error.response);
      window.history.pushState({}, undefined, "/internal/login");
      navigate("/internal/login", { replace: true });
    } else {
      setApiErrorMessage(error.message);
    }
    console.log(error.message);
  }

  function buildParams(params) {
    let parameterString = "";
    if (params && Array.isArray(params)) {
      for (const param of params) {
        if (parameterString === "") {
          parameterString += "?";
        } else {
          parameterString += "&";
        }
        parameterString += `${param.name}=${param.value}`;
      }
    }
    return parameterString;
  }

  async function getPageData(pageUri, params) {
    try {
      setApiErrorMessage("");
      setIsLoading(true);
      const result = await axios.get(serverUrl + routeUrl + pageUri + buildParams(params), { withCredentials: true });
      setIsLoading(false);
      if (!handleResultErrors(result)) {
        console.log("Can now display the page");
        return result.data;
      }
      return false;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      handleCatchErrors(error);
      return false;
    }
  }

  async function postProceed(uri, payload) {
    try {
      setApiErrorMessage("");
      setIsLoading(true);
      const result = await axios.post(serverUrl + routeUrl + uri, { payload }, { withCredentials: true });
      setIsLoading(false);
      if (!handleResultErrors(result)) {
        return true;
      }
      return false;
    } catch (error) {
      setIsLoading(false);
      handleCatchErrors(error);
      return false;
    }
  }

  function handleResultErrors(result) {
    if (result.data.errorMessage) {
      console.log("Setting Error");
      setApiErrorMessage(result.data.errorMessage);
      return true;
    }
    return false;
  }

  return {
    checkAuth,
    getPageData,
    postProceed,
    isLoading,
    errorMessage,
  };
};
