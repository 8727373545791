import React, { useState, useEffect, useRef } from "react";
import { Image, InputRightElement, InputGroup, InputLeftElement, Stack, Input, Flex, Button, Box, Divider, Heading, Text, useToast, Progress } from "@chakra-ui/react";
import MarkerLogo from "../../../images/google-marker.svg";
// import GoogleMarker from "../../../images/GoogleMarker";
import { useApi } from "../../../hooks/customer/useApi";
import { CloseIcon } from "@chakra-ui/icons";
import AnchorLogo from "../../../images/anchor.svg";
import Anchor from "../../../images/Anchor";
import {
  loadGMaps,
  createMap,
  initDirectionsService,
  initDirectionsDisplay,
  initAutoComplete,
  showDestinationIcon,
  buildDirectionsRequest,
  initJourneyStartMarker,
  extendBoundsForLocation,
  positionMarker,
} from "../../../scripts/google-maps-helper.js";
import { useNavigate } from "react-router-dom";

function DepartureTimeAndLocation() {
  const [showLocationX, setShowLocationX] = useState(false);
  const [state, setState] = useState({ checkinConfig: { configId: -1 }, currentStartLocation: {}, currentJourneyMetrics: {} });

  const directionsDisplay = useRef();
  const journeyStartMarker = useRef();

  const navigate = useNavigate();

  const toast = useToast();
  const api = useApi();

  useEffect(() => {
    api.checkAuth();
    loadGMaps(async () => {
      const map = createMap();
      // showDestinationIcon(map, Anchor);
      showDestinationIcon(map, AnchorLogo);
      journeyStartMarker.current = initJourneyStartMarker(map);
      const directionsService = initDirectionsService();
      directionsDisplay.current = initDirectionsDisplay(map);
      const autocomplete = initAutoComplete(map);

      const data = await api.getPageData("departure-time-and-location");
      if (data) {
        setState((oldState) => {
          return { ...oldState, ...data };
        });
        if (data.currentStartLocation) {
          setShowLocationX(true);
          document.getElementById("pac-input").value = data.currentStartLocation.locationText;
          updateFromLocation(map, directionsService, data.currentStartLocation.locationLatLong);
        }
      }
      addAutocompleteListener(autocomplete, map, directionsService);
    });
    return () => {};
  }, []);

  function handleLocationInputUpdate(e) {
    e.preventDefault();
    setShowLocationX(e.target.value.length > 0);
  }

  function setCurrentStartLocation(locationText, locationLatLong) {
    setState((oldState) => {
      return {
        ...oldState,
        currentStartLocation: {
          locationText: locationText,
          locationLatLong: locationLatLong,
        },
      };
    });
  }

  function setCurrentJourneyMetrics(distance, duration) {
    setState((oldState) => {
      return {
        ...oldState,
        currentJourneyMetrics: {
          distance: distance,
          duration: duration,
        },
      };
    });
  }

  function routeAndRenderNewDirections(map, directionsService, request) {
    directionsService.route(request, (result, status) => {
      if (status === "OK") {
        setCurrentJourneyMetrics(result.routes[0].legs[0].distance.value, result.routes[0].legs[0].duration.value);
        directionsDisplay.current.setMap(map);
        directionsDisplay.current.setDirections(result);
      } else {
        setCurrentJourneyMetrics(null, null);
      }
    });
  }

  async function updateFromLocation(map, directionsService, location) {
    extendBoundsForLocation(map, location);
    positionMarker(journeyStartMarker.current, location, true);

    routeAndRenderNewDirections(map, directionsService, buildDirectionsRequest(location));
  }

  function addAutocompleteListener(autocomplete, map, directionsService) {
    autocomplete.addListener("place_changed", () => {
      journeyStartMarker.current.setVisible(false);
      const place = autocomplete.getPlace();
      const inputText = document.getElementById("pac-input").value;
      directionsDisplay.current.setMap(null);

      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        //   directionsDisplay.setDirections(null);
        setCurrentStartLocation("", "");
        toast({
          title: "Pick from dropdown.",
          description: "New locations must be picked from the suggestions",
          status: "warning",
          duration: 3500,
          isClosable: true,
          position: "bottom",
        });
        return;
      }
      let address = "";
      if (place.address_components) {
        address = [
          (place.address_components[0] && place.address_components[0].short_name) || "",
          (place.address_components[1] && place.address_components[1].short_name) || "",
          (place.address_components[2] && place.address_components[2].short_name) || "",
        ].join(" ");
      }
      setCurrentStartLocation(inputText, place.geometry.location);
      updateFromLocation(map, directionsService, place.geometry.location);
    });
  }

  function handleXClicked(e) {
    setCurrentStartLocation("", null);
    setCurrentJourneyMetrics(null, null);
    journeyStartMarker.current.setVisible(false);
    directionsDisplay.current.setMap(null);
    const pacInput = document.getElementById("pac-input");
    pacInput.value = "";
    pacInput.focus();
    setShowLocationX(false);
  }

  async function handleContinue(e) {
    if (!state.currentStartLocation.locationText || !state.currentStartLocation.locationLatLong) {
      toast({
        title: "Input a valid location.",
        description: "You must indicate where you are travelling from tomorrow morning.",
        status: "warning",
        duration: 3500,
        isClosable: true,
        position: "bottom",
      });
    } else if (!state.currentJourneyMetrics.distance || !state.currentJourneyMetrics.duration) {
      toast({
        title: "No route available from that location.",
        description: "Please input a location with an available route.",
        status: "warning",
        duration: 4500,
        isClosable: true,
        position: "bottom",
      });
    } else {
      const payload = {
        configId: state.checkinConfig.configId,
        locationText: state.currentStartLocation.locationText,
        locationLatLong: state.currentStartLocation.locationLatLong,
        distance: state.currentJourneyMetrics.distance,
        duration: state.currentJourneyMetrics.duration,
      };
      if (await api.postProceed("departure-time-and-location", payload)) {
        window.history.pushState({}, undefined, "/journey-to-portmagee");
        navigate("/journey-to-portmagee", { replace: true });
      }
    }
  }

  async function handleBack() {
    navigate(-1);
  }

  return (
    <Flex direction="column" align="center" w="full">
      <Box p={5} width="100%" maxWidth={700} minHeight={"100vh"} borderWidth={1} borderRadius={8} boxShadow="lg">
        {/* <Box textAlign="left"> */}
        {/* <Heading>Arriving in the Morning</Heading> */}
        {/* </Box> */}
        <Progress value={40}></Progress>
        <Text mt="3" textAlign="left">
          You must be at the <b>Portmagee marina</b> <Image display="inline" src={AnchorLogo} alt="Portmagee" /> at <b>{state.checkinConfig ? state.checkinConfig.formattedArrivalTime : ""}</b>
        </Text>
        <Text mt="1" textAlign="left">
          Ask there for <b>BRENDAN CASEY</b>.
        </Text>

        <Text mt="3" textAlign="left">
          Where will you be coming from tomorrow morning?
        </Text>
        <Stack mt={1} spacing={4}>
          <InputGroup>
            <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em" children={<Image maxHeight={6} src={MarkerLogo} alt="Journey Start" />} />
            <Input id="pac-input" placeholder="Start typing a location.." onChange={handleLocationInputUpdate} />
            {showLocationX && <InputRightElement onClick={handleXClicked} cursor="pointer" children={<CloseIcon color="gray.500" />} />}
          </InputGroup>
        </Stack>
        {api.errorMessage && (
          <Text my="1" color="red.500">
            {api.errorMessage}
          </Text>
        )}
        <Divider mt={3} />
        <Flex my="2" justifyContent="space-between">
          <Button isLoading={api.isLoading} onClick={handleBack} type="submit" variant="solid" colorScheme="gray">
            Back
          </Button>
          <Button isLoading={api.isLoading} onClick={handleContinue} type="submit" variant="solid" colorScheme="green">
            Save & Next
          </Button>
        </Flex>
        {/* <Box mt="5">{loaded ? <GoogleMap /> : ""}</Box> */}
        <div id="map" style={{ height: "400px" }} />
      </Box>
    </Flex>
  );
}

export default DepartureTimeAndLocation;
