import {
  Menu,
  MenuButton,
  Input,
  MenuList,
  InputLeftElement,
  MenuItem,
  InputGroup,
  InputRightAddon,
  Stack,
  Flex,
  Spacer,
  Divider,
  Button,
  Box,
  Heading,
  InputLeftAddon,
  Text,
  Progress,
} from "@chakra-ui/react";
import { ChevronDownIcon, InfoIcon, PhoneIcon } from "@chakra-ui/icons";

import React, { useState, useEffect } from "react";
import { useApi } from "../../../hooks/customer/useApi";

import PhoneNumberInput from "../../PhoneNumberEntry/PhoneNumberInput";

import { useNavigate } from "react-router-dom";

function Login() {
  const api = useApi();
  const [state, setState] = useState({
    isOriginalNumberReachable: null,
    isOriginalAlternativeNumberReachable: null,
    additionalNumberZero: { isoCode: "IE", mainNumber: "" },
    additionalNumberOne: { isoCode: "IE", mainNumber: "" },
    additionalNumberTwo: { isoCode: "IE", mainNumber: "" },
    // acknowledgedUncontactableAt: "",
    formattedExistingNumber: "",
    formattedExistingAlternativeNumber: "",
    shouldShowUnreachableWarning: false,
    showIsNumberContactableMandatory: false,
    showIsAlternativeNumberContactableMandatory: false,
    showAdditionalNumberEntry0: false,
    showAdditionalNumberEntry1: false,
    showAdditionalNumberEntry2: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    async function initPage() {
      const data = await api.getPageData("contact-details");
      if (data) {
        setState({
          ...state,
          configId: data.configId,
          isOriginalNumberReachable: !data.formattedExistingNumber ? false : data.isOriginalNumberReachable,
          isOriginalAlternativeNumberReachable: !data.formattedExistingAlternativeNumber ? false : data.isOriginalAlternativeNumberReachable,
          additionalNumberZero: data.additionalNumberZero ? data.additionalNumberZero : state.additionalNumberZero,
          additionalNumberOne: data.additionalNumberOne ? data.additionalNumberOne : state.additionalNumberOne,
          additionalNumberTwo: data.additionalNumberTwo ? data.additionalNumberTwo : state.additionalNumberTwo,
          formattedExistingNumber: data.formattedExistingNumber,
          formattedExistingAlternativeNumber: data.formattedExistingAlternativeNumber,
          showAdditionalNumberEntry1: data.additionalNumberOne && data.additionalNumberOne.mainNumber ? true : false,
          showAdditionalNumberEntry2: data.additionalNumberTwo && data.additionalNumberTwo.mainNumber ? true : false,
        });
      }
    }

    initPage();
    return () => {};
  }, []);

  function isOriginalNumberReachableNotFilledOut() {
    return state.isOriginalNumberReachable !== true && state.isOriginalNumberReachable !== false;
  }

  function isOriginalAlternativeNumberReachableIgnored() {
    return state.formattedExistingAlternativeNumber && state.isOriginalAlternativeNumberReachable !== true && state.isOriginalAlternativeNumberReachable !== false;
  }

  function isNotReachableOnAnyNumber() {
    return (
      state.isOriginalNumberReachable !== true &&
      state.isOriginalAlternativeNumberReachable !== true &&
      !state.additionalNumberZero.mainNumber &&
      !state.additionalNumberOne.mainNumber &&
      !state.additionalNumberTwo.mainNumber
    );
  }

  async function handleProceed(e) {
    if (isOriginalNumberReachableNotFilledOut()) {
      setState({ ...state, showIsNumberContactableMandatory: true });
    } else if (isOriginalAlternativeNumberReachableIgnored()) {
      setState({ ...state, showIsAlternativeNumberContactableMandatory: true });
    } else if (isNotReachableOnAnyNumber() && !state.shouldShowUnreachableWarning) {
      setState({ ...state, shouldShowUnreachableWarning: true });
    } else {
      setState({ ...state, showIsNumberContactableMandatory: false, showIsAlternativeNumberContactableMandatory: false, shouldShowUnreachableWarning: false });
      if (
        await api.postProceed("contact-details", {
          configId: state.configId,
          additionalNumberZero: state.additionalNumberZero,
          additionalNumberOne: state.additionalNumberOne,
          additionalNumberTwo: state.additionalNumberTwo,
          isOriginalNumberReachable: state.isOriginalNumberReachable,
          isOriginalAlternativeNumberReachable: state.isOriginalAlternativeNumberReachable,
        })
      ) {
        window.history.pushState({}, undefined, "/finish");
        navigate("/finish", { replace: true });
      }
    }
  }

  function handleReachableChange(e) {
    e.preventDefault();
    setState({
      ...state,
      showIsNumberContactableMandatory: false,
      isOriginalNumberReachable: JSON.parse(e.target.value),
    });
  }

  function handleAdditionalNumberReachableChange(e) {
    e.preventDefault();
    setState({
      ...state,
      showIsAlternativeNumberContactableMandatory: false,
      isOriginalAlternativeNumberReachable: JSON.parse(e.target.value),
    });
  }

  async function handleAdditionalNumberChange(attributeName, isoCode, mainNumber) {
    setState({
      ...state,
      [attributeName]: {
        isoCode,
        mainNumber,
      },
    });
  }

  async function handleBack(e) {
    e.preventDefault();
    navigate(-1);
  }

  async function handleAdditionalNumberEntry1(e) {
    e.preventDefault();
    setState({
      ...state,
      showAdditionalNumberEntry1: true,
    });
  }

  async function handleAdditionalNumberEntry2(e) {
    e.preventDefault();
    setState({
      ...state,
      showAdditionalNumberEntry2: true,
    });
  }

  return (
    <Flex direction="column" align="center" w="full">
      <Box p={5} width="100%" maxWidth={700} minHeight={"100vh"} borderWidth={1} borderRadius={8} boxShadow="lg">
        <Box textAlign="left">
          <Progress value={80}></Progress>
          <Heading size="lg" mt="4">
            Contact Details
          </Heading>
        </Box>
        <Text textAlign="left" mt={3}>
          The staff on Skellig Michael <b>will assess the conditions at the island in the morning</b> and will decide if the landing tours can go ahead.
        </Text>
        <Text textAlign="left" mt={2}>
          They usually make the final decision <b>just before the departure time.</b> Of course, <b>we will contact you immediately</b> if they update us before then.
        </Text>
        <Text textAlign="left" mt={2}>
          For this reason, <b>it is important that you correctly enter your contact information</b> below.
        </Text>
        <Divider my="3" />
        <Box textAlign="left">
          <Heading as="h2" size="md">
            Phone numbers
          </Heading>
          {state.formattedExistingNumber && (
            <>
              <Text color="gray.500">Entered when you booked:</Text>
              <Stack spacing={4}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<PhoneIcon color="gray.500" />} />
                  <Input type="tel" value={state.formattedExistingNumber} color="gray.900" style={{ opacity: "1" }} backgroundColor={"gray.100"} isDisabled={true} />
                </InputGroup>
              </Stack>
              <Flex mt="2" justifyContent="flex-end" alignItems="center">
                <Text pr={1}>Is this phone number good to contact you?</Text>
                <Spacer />
                <Menu>
                  <MenuButton px={"5px"} colorScheme={state.showIsNumberContactableMandatory ? "red" : "black"} as={Button} minWidth="80px" variant="outline" rightIcon={<ChevronDownIcon />}>
                    {state.isOriginalNumberReachable !== true && state.isOriginalNumberReachable !== false ? "Select" : ""}
                    {state.isOriginalNumberReachable === true ? "Yes" : ""}
                    {state.isOriginalNumberReachable === false ? "No" : ""}
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={handleReachableChange} value={true}>
                      Yes
                    </MenuItem>
                    <MenuItem onClick={handleReachableChange} value={false}>
                      No
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </>
          )}

          {state.formattedExistingAlternativeNumber && (
            <>
              <Text color="gray.500" mt="3">
                Also entered when you booked:
              </Text>
              <Stack spacing={4}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<PhoneIcon color="gray.500" />} />
                  <Input type="tel" value={state.formattedExistingAlternativeNumber} color="gray.900" style={{ opacity: "1" }} backgroundColor={"gray.100"} isDisabled={true} />
                </InputGroup>
              </Stack>
              <Flex mt="2" justifyContent="flex-end" alignItems="center">
                <Text pl="1">Is this phone number good to contact you?</Text>
                <Spacer />
                <Menu>
                  <MenuButton
                    px={"5px"}
                    colorScheme={state.showIsAlternativeNumberContactableMandatory ? "red" : "black"}
                    as={Button}
                    minWidth="80px"
                    variant="outline"
                    rightIcon={<ChevronDownIcon />}
                  >
                    {state.isOriginalAlternativeNumberReachable !== true && state.isOriginalAlternativeNumberReachable !== false ? "Select" : ""}
                    {state.isOriginalAlternativeNumberReachable === true ? "Yes" : ""}
                    {state.isOriginalAlternativeNumberReachable === false ? "No" : ""}
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={handleAdditionalNumberReachableChange} value={true}>
                      Yes
                    </MenuItem>
                    <MenuItem onClick={handleAdditionalNumberReachableChange} value={false}>
                      No
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </>
          )}
          {state.formattedExistingNumber || state.formattedExistingAlternativeNumber ? (
            <Box mt="6">
              <Text textAlign="left">Please enter any additional phone numbers that we will be able to contact you on.</Text>
            </Box>
          ) : (
            <>
              <Text mt="2">No phone numbers were entered when you booked. Please enter any phone numbers that we will be able to contact you on.</Text>
            </>
          )}
        </Box>

        <Box mt="3">
          <PhoneNumberInput nameInState="additionalNumberZero" phoneNumber={state.additionalNumberZero} handleChange={handleAdditionalNumberChange}></PhoneNumberInput>
        </Box>
        {!state.showAdditionalNumberEntry1 && (
          <>
            <Divider mt="3"></Divider>
            <Button onClick={handleAdditionalNumberEntry1} my="2">
              + Add Additional Number
            </Button>
          </>
        )}
        {state.showAdditionalNumberEntry1 && (
          <Box mt="3">
            <PhoneNumberInput nameInState="additionalNumberOne" phoneNumber={state.additionalNumberOne} handleChange={handleAdditionalNumberChange}></PhoneNumberInput>
          </Box>
        )}
        {state.showAdditionalNumberEntry1 && !state.showAdditionalNumberEntry2 && (
          <>
            <Divider mt="3"></Divider>
            <Button onClick={handleAdditionalNumberEntry2} my="2">
              + Add Additional Number
            </Button>
          </>
        )}
        {state.showAdditionalNumberEntry2 && (
          <Box mt="3">
            <PhoneNumberInput nameInState="additionalNumberTwo" phoneNumber={state.additionalNumberTwo} handleChange={handleAdditionalNumberChange}></PhoneNumberInput>
          </Box>
        )}
        {state.showIsNumberContactableMandatory || state.showIsAlternativeNumberContactableMandatory ? (
          <Text mt="5" color="red.500" textAlign="left">
            Please select whether your number is reachable before the tour.
          </Text>
        ) : null}
        {api.errorMessage && (
          <Text mt="5" minHeight="10" color="red.500">
            {api.errorMessage}
          </Text>
        )}
        {state.shouldShowUnreachableWarning && (
          <Text mt="5" color="red.500" textAlign="left">
            If you don't enter a phone number, our only way to contact you with any updates or tour cancellations will be through email, so please check your email regularly.
          </Text>
        )}
        <Divider mt={3} />
        <Flex justifyContent="space-between" my="3">
          <Button isLoading={api.isLoading} onClick={handleBack} type="submit" variant="solid" colorScheme="gray">
            Back
          </Button>
          <Button isLoading={api.isLoading} data-accept-not-contactable={false} onClick={handleProceed} type="submit" variant="solid" colorScheme="green">
            Save & Next
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
}

export default Login;
