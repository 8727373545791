import React, { useEffect, useState } from "react";
import { Flex, Divider, Button, Box, Heading, Text, Progress, UnorderedList, ListItem, Image } from "@chakra-ui/react";
import { useApi } from "../../../hooks/customer/useApi";
// import {authContext} from "../contexts/AuthContext";
import VariedText from "../../VariedText/VariedText";
import AnchorLogo from "../../../images/anchor.svg";
import { useNavigate } from "react-router-dom";

function ThankYou() {
  // const authContext = useContext(authContext);
  const [state, setState] = useState({});
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    api.checkAuth();
    async function initPage() {}

    initPage();
    return () => {};
  }, []);

  return (
    <Flex direction="column" align="center" w="full">
      <Box p={5} width="100%" maxWidth={700} minHeight={"100vh"} borderWidth={1} borderRadius={8} boxShadow="lg">
        <Box textAlign="left">
          <Progress value={100}></Progress>
        </Box>

        <Heading mt={4} textAlign={"left"} size="lg">
          Thank You
        </Heading>
        <Text mt="3" textAlign="left">
          Thank you for completing your check-in.
        </Text>
        <Text my="3" textAlign="left">
          A confirmation email will also have been sent.
        </Text>
        {api.errorMessage && (
          <Text minHeight="10" color="red.500">
            {api.errorMessage}
          </Text>
        )}
      </Box>
    </Flex>
  );
}

export default ThankYou;
