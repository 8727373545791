import React from "react";
import DatePickerComponent from "./DatePickerComponent";

import {
  Badge,
  Accordion,
  AccordionItem,
  AccordionButton,
  Menu,
  AccordionPanel,
  MenuItemOption,
  Flex,
  Divider,
  Button,
  Box,
  Heading,
  Tag,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  MenuButton,
  MenuList,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  AccordionIcon,
  Input,
} from "@chakra-ui/react";

function DaySelector({ chosenDate, handleDateChange }) {
  function handleMinusADay() {
    const d = new Date(chosenDate);
    d.setDate(d.getDate() - 1);
    handleDateChange(d);
  }

  function handlePlusADay() {
    const d = new Date(chosenDate);
    d.setDate(d.getDate() + 1);
    handleDateChange(d);
  }

  function handleToday() {
    handleDateChange(new Date());
  }
  return (
    <>
      <Flex justifyContent={"space-between"}>
        <Button colorScheme={"green"} variant={"outline"} size={"sm"} onClick={handleMinusADay}>
          - Day
        </Button>
        <DatePickerComponent chosenDate={chosenDate} handleDateChange={handleDateChange}></DatePickerComponent>
        <Button colorScheme={"green"} variant={"outline"} size={"sm"} onClick={handleToday}>
          To Today
        </Button>
        <Button colorScheme={"green"} variant={"outline"} size={"sm"} onClick={handlePlusADay}>
          + Day
        </Button>
      </Flex>
    </>
  );
}

export default DaySelector;
