const portmageeMarkerLatLng = { lat: 51.885868, lng: -10.365362 };

export const loadGMaps = (callback) => {
  const existingScript = document.getElementById("googleMaps");
  if (!existingScript) {
    console.log("Not existing");
    const script = document.createElement("script");
    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCRklsxqV9yRIX9E16bYjOgpx2EWzptkX4&libraries=places";
    script.id = "googleMaps";
    document.body.appendChild(script);
    script.onload = () => {
      console.log("LOADED GOOGLE MAPS FIRED");
      if (callback) callback();
    };
  }
  if (existingScript && callback && window.google) callback();
};

export function createMap() {
  console.log("createMap", "createMapcreateMapcreateMapcreateMap");
  console.log("window.google.maps", window.google);
  return new window.google.maps.Map(document.getElementById("map"), {
    center: { lat: 51.95357, lng: -10.175266 },
    zoom: 9.5,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
  });
}

export function initDirectionsService() {
  return new window.google.maps.DirectionsService();
}

export function initDirectionsDisplay(map) {
  const directionsDisplay = new window.google.maps.DirectionsRenderer({
    suppressMarkers: true,
  });
  directionsDisplay.setMap(map);
  return directionsDisplay;
}

export function initAutoComplete(map) {
  let input = document.getElementById("pac-input");
  const autoCompleteOptions = {
    componentRestrictions: { country: ["ie", "uk"] },
  };
  let autocomplete = new window.google.maps.places.Autocomplete(input, autoCompleteOptions);
  autocomplete.bindTo("bounds", map);
  return autocomplete;
}

export function showDestinationIcon(map, AnchorLogo) {
  let image = {
    url: AnchorLogo,
    scaledSize: new window.google.maps.Size(32, 32),
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(16, 32),
  };

  let marinaMarker = new window.google.maps.Marker({
    position: portmageeMarkerLatLng,
    map: map,
    icon: image,
  });
  marinaMarker.setVisible(true);
}

export function buildDirectionsRequest(location) {
  return {
    origin: location,
    destination: portmageeMarkerLatLng,
    travelMode: "DRIVING",
  };
}

export function initJourneyStartMarker(map) {
  return new window.google.maps.Marker({
    map: map,
    anchorPoint: new window.google.maps.Point(51.95357, -10.175266),
  });
}

export function extendBoundsForLocation(map, location) {
  let baseBounds = new window.google.maps.LatLngBounds({ lat: 51.855, lng: -10.39 }, { lat: 51.956, lng: -10.29 });
  let extendedBounds = baseBounds.extend(location);
  map.fitBounds(extendedBounds);
}

export function positionMarker(marker, location, isVisible) {
  marker.setPosition(location);
  marker.setVisible(isVisible);
}
