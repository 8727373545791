import "./App.css";
import { Routes, Route } from "react-router-dom";

import AuthProvider from "./contexts/AuthContext";

// Customer Checkin
import CustomerLogin from "./components/customer/pages/Login";
import Welcome from "./components/customer/pages/CheckIn";
import ContactDetails from "./components/customer/pages/ContactDetails";
import DepartureTimeAndLocation from "./components/customer/pages/DepartureTimeAndLocation";
import JourneyToPortmagee from "./components/customer/pages/JourneyToPortmagee";
import SeaWeatherConditions from "./components/customer/pages/SeaWeatherConditions";
import Finish from "./components/customer/pages/Finish";
import ScrollToTop from "./components/ScrollToTop";
import ThankYou from "./components/customer/pages/ThankYou";
import Landing from "./components/customer/pages/Landing";

// Internal Users
import InternalLogin from "./components/internal/pages/Login";
import CheckinConfiguration from "./components/internal/pages/CheckinConfiguration";
import EventOverview from "./components/internal/pages/EventOverview";
import EventsList from "./components/internal/pages/EventsList";

import { Flex } from "@chakra-ui/react";
import ContactsExport from "./components/internal/pages/ContactsExport";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Flex direction="column" align="center" justify="center">
          <ScrollToTop>
            <Routes>
              <Route path="/check-in" exact element={<Welcome />}></Route>
              <Route path="/sea-weather-conditions" exact element={<SeaWeatherConditions />}></Route>
              <Route path="/departure-time-and-location" exact element={<DepartureTimeAndLocation />}></Route>
              <Route path="/journey-to-portmagee" exact element={<JourneyToPortmagee />}></Route>
              <Route path="/contact-details" exact element={<ContactDetails />}></Route>
              <Route path="/finish" exact element={<Finish />}></Route>
              <Route path="/thank-you" exact element={<ThankYou />}></Route>

              <Route path="/internal/events-list" exact element={<EventsList />} />
              <Route path="/internal/event-overview" exact element={<EventOverview />} />
              <Route path="/internal/contacts-export" exact element={<ContactsExport />} />
              <Route path="/internal/checkin-configuration" exact element={<CheckinConfiguration />} />
              <Route path="/internal" exact element={<InternalLogin />} />
              <Route path="/internal/*" exact element={<InternalLogin />} />

              <Route path="login" exact element={<CustomerLogin />} />
              <Route path="*" exact element={<Landing />} />

              {/* <Route path="/sample" render={(routerProps) => <Sample {...routerProps} sampleProp={"sample"} />} />
        <Route path="/something" component={Something} />
        <Route path="/default" render={() => <Redirect to="/" />} />
        <Route component={NoMatch} /> */}
            </Routes>
          </ScrollToTop>
        </Flex>
      </AuthProvider>
    </div>
  );
}

export default App;
