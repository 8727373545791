import React, { useState, useEffect } from "react";
import { Stack, Flex, Button, Box, Heading, Text, Progress, Divider } from "@chakra-ui/react";
import { useApi } from "../../../hooks/customer/useApi";
import { useNavigate } from "react-router-dom";

export default function SeaWeatherConditions() {
  const api = useApi();
  const navigate = useNavigate();

  const [state, setState] = useState({});

  useEffect(() => {
    async function initPage() {
      const data = await api.getPageData("weather-outlook");
      if (data) {
        setState(data);
      }
    }

    initPage();
    return () => {};
  }, []);

  async function handleProceed() {
    if (await api.postProceed("weather-outlook", { configId: state.configId })) {
      window.history.pushState({}, undefined, "/departure-time-and-location");
      navigate("/departure-time-and-location", { replace: true });
    }
  }

  async function handleBack() {
    navigate(-1);
  }

  return (
    <Flex direction="column" align="center" w="full">
      <Box p={5} width="100%" maxWidth={700} minHeight={"100vh"} borderWidth={1} borderRadius={8} boxShadow="lg">
        <Box textAlign="left">
          <Progress value={20}></Progress>
          <Heading size="lg" mt="4">
            Sea/Weather Conditions
          </Heading>
        </Box>
        <Stack mt={3} spacing={4}>
          {state.formattedWeatherOutlook && <Text textAlign="left">{state.formattedWeatherOutlook}</Text>}
          {state.importantToBeContactable &&
            state.importantToBeContactable.map((formattedImportantToBeContactable, index) => {
              return (
                <Text key={index} textAlign="left" mt={0} py={0}>
                  {formattedImportantToBeContactable}
                </Text>
              );
            })}
          {state.formattedCancellationLiklihood && <Text textAlign="left">{state.formattedCancellationLiklihood}</Text>}

          {state.formattedGuideInfluence && <Text textAlign="left">{state.formattedGuideInfluence}</Text>}
        </Stack>
        {api.errorMessage && (
          <Text minHeight="10" color="red.500">
            {api.errorMessage}
          </Text>
        )}
        <Divider mt={3} />
        <Flex my="3" justifyContent="space-between">
          <Button isLoading={api.isLoading} onClick={handleBack} type="submit" variant="solid" colorScheme="gray">
            Back
          </Button>
          <Button isLoading={api.isLoading} onClick={handleProceed} type="submit" variant="solid" colorScheme="green">
            Next
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
}
