import React from "react";

function Anchor() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.523" height="19.704" overflow="visible" viewBox="0 0 18.523 19.704">
      <switch>
        <g>
          <path
            fill="#275090"
            d="M9.243 3.5a.767.767 0 10-.001-1.53.767.767 0 00.001 1.53zm6.323 7.272l2.109-1.708.424 2.681.424 2.682-1.324-.508a8.337 8.337 0 01-7.937 5.785 8.341 8.341 0 01-7.939-5.785L0 14.427l.425-2.682.424-2.681 2.11 1.708 2.108 1.709-1.582.607a6.036 6.036 0 004.604 4.191V7.852H4.383V5.736h3.706v-.522a2.734 2.734 0 112.308-.001v.522h3.513v2.116h-3.513v9.436a6.032 6.032 0 004.642-4.199l-1.582-.607 2.109-1.709z"
          ></path>
        </g>
      </switch>
    </svg>
  );
}

export default Anchor;
