import { FormControl, InputGroup, InputLeftElement, Stack, Input, Flex, Divider, Button, Box, Heading, FormErrorMessage, FormLabel, Text, FormHelperText } from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { EmailIcon, CalendarIcon } from "@chakra-ui/icons";
import { useApi } from "../../../hooks/customer/useApi";
import { authContext } from "../../../contexts/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";

function Login() {
  const api = useApi();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const auth = useContext(authContext);

  // const [bookingNumber, setBookingNumber] = useState("2551102051655332");
  const [bookingNumber, setBookingNumber] = useState("");
  const [bookingNumberError, setBookingNumberError] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");

  useEffect(() => {
    // api.checkAuth();
    async function initPage() {
      const data = await api.getPageData("landing");
      if (data && !data.isAutoCheckinOn) {
        window.history.pushState({}, undefined, "/");
        navigate("/", { replace: true });
      }
      const currentBookingNumberParam = searchParams.get("bookingNumber");
      if (currentBookingNumberParam) {
        setBookingNumber(currentBookingNumberParam);
      }
      const ml = searchParams.get("ml");
      if (ml) {
        if (await api.mlLogin(ml)) {
          window.history.pushState({}, undefined, "/check-in");
          navigate("/check-in", { replace: true });
        }
      }
    }

    initPage();
  }, []);

  function handleBookingNumberChange(e) {
    if (bookingNumberError) {
      setBookingNumberError("");
    }
    setBookingNumber(e.target.value);
  }

  function handleEmailNumberChange(e) {
    if (emailAddressError) {
      setEmailAddressError("");
    }
    setEmailAddress(e.target.value);
  }

  function isFormValid() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = re.test(String(emailAddress).toLowerCase());
    if (!isValidEmail) {
      setEmailAddressError("Please input a proper email address");
    }
    const isValidBookingNumber = bookingNumber;
    if (!isValidBookingNumber) {
      setBookingNumberError("Please input a booking number");
    }
    return isValidEmail && isValidBookingNumber;
  }

  async function handleProceed(e) {
    e.preventDefault();
    if (isFormValid()) {
      if (await api.login(bookingNumber, emailAddress)) {
        window.history.pushState({}, undefined, "/check-in");
        navigate("/check-in", { replace: true });
      }
    }
  }

  return (
    <Flex direction="column" align="center" w="full">
      <Box p={8} width="100%" maxWidth={700} minHeight={"100vh"} borderWidth={1} borderRadius={8} boxShadow="lg">
        <Box textAlign="center">
          <Heading>Check-in for Skellig Michael Landing Tour</Heading>
        </Box>
        <Text textAlign={"left"} mt={3}>
          Check-in opens at 04:00 and closes at 16:00 on the day before the tour.
        </Text>
        <Box my={10} textAlign="left">
          <form action="submit">
            <Stack spacing="3">
              <FormControl isRequired isInvalid={bookingNumberError}>
                <FormLabel>Booking Number</FormLabel>
                <InputGroup>
                  <InputLeftElement children={<CalendarIcon />} />
                  <Input onChange={handleBookingNumberChange} value={bookingNumber} type="number" placeholder="Booking Number" aria-label="Booking Number" />
                </InputGroup>
                <FormHelperText>The booking number from our email.</FormHelperText>

                <FormErrorMessage>{bookingNumberError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={emailAddressError}>
                <FormLabel>Email Address</FormLabel>
                <InputGroup>
                  <InputLeftElement children={<EmailIcon />} />
                  <Input onChange={handleEmailNumberChange} value={emailAddress} type="email" placeholder="Email Address" aria-label="Email Address" />
                </InputGroup>
                <FormHelperText>The email address associated with your booking.</FormHelperText>
                <FormErrorMessage>{emailAddressError}</FormErrorMessage>
              </FormControl>
              <Divider />
              {auth.error && (
                <Text minHeight="10" color="red.500">
                  {auth.error}
                </Text>
              )}
              {api.errorMessage && (
                <Text minHeight="10" color="red.500">
                  {api.errorMessage}
                </Text>
              )}
              <Divider />
              <Button isLoading={api.isLoading} onClick={handleProceed} type="submit" variant="solid" colorScheme="green">
                Continue
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </Flex>
  );
}

export default Login;
