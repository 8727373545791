import React from "react";
import { Stack, Select, InputGroup, InputLeftAddon, Input } from "@chakra-ui/react";
import { internationalCodes, commonlyUsedCodes, isoCodeToDialCodeMap } from "../../config/internationalphonecodes";

function PhoneNumberInput({ nameInState, phoneNumber, handleChange }) {
  function handleCodeChange(e) {
    e.preventDefault();
    handleChange(nameInState, e.target.value, phoneNumber.mainNumber);
  }

  function handleNumberChange(e) {
    e.preventDefault();
    if (e.target.value.length < 24 && (e.target.value === "" || /^\d+$/.test(e.target.value))) {
      handleChange(nameInState, phoneNumber.isoCode, e.target.value);
    }
  }

  return (
    <Stack spacing={1}>
      <Select variant="outline" value={phoneNumber ? phoneNumber.isoCode : ""} onChange={handleCodeChange}>
        <optgroup label="Commonly used">
          {commonlyUsedCodes.map((code) => {
            return <option key={code.index} value={code.isoCode}>{`${code.name} (${isoCodeToDialCodeMap[code.isoCode]})`}</option>;
          })}
        </optgroup>
        <optgroup label="All international codes">
          {internationalCodes.map((code) => {
            return <option key={code.index} value={code.isoCode}>{`${code.name} (${isoCodeToDialCodeMap[code.isoCode]})`}</option>;
          })}
        </optgroup>
      </Select>
      <InputGroup>
        <InputLeftAddon children={isoCodeToDialCodeMap[phoneNumber ? phoneNumber.isoCode : ""]} />
        <Input type="tel" placeholder="Phone number" value={phoneNumber ? phoneNumber.mainNumber : ""} onChange={handleNumberChange} />
      </InputGroup>
    </Stack>
  );
}

export default PhoneNumberInput;
