import React, { useEffect, useState } from "react";
import {
  Badge,
  Accordion,
  AccordionItem,
  AccordionButton,
  Menu,
  AccordionPanel,
  MenuItemOption,
  Flex,
  Divider,
  Button,
  Box,
  Heading,
  Tag,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  MenuButton,
  MenuList,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  AccordionIcon,
} from "@chakra-ui/react";
import { ArrowForwardIcon, ChevronDownIcon, SettingsIcon } from "@chakra-ui/icons";
import { useApi } from "../../../hooks/internal/useApi";
// import {authContext} from "../contexts/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import DaySelector from "../../SelectorDate/DaySelector";

function EventsList() {
  // const authContext = useContext(authContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const api = useApi();
  const [state, setState] = useState({
    currentDate: null,
    data: [],
  });

  useEffect(() => {
    api.checkAuth();

    initialiseDate();
    return () => {};
  }, []);

  async function initialiseDate() {
    const currentDateParam = searchParams.get("currentDate");
    let d = null;

    if (currentDateParam) {
      d = new Date(currentDateParam);
      if (!(d instanceof Date && !isNaN(d))) {
        d = new Date();
      }
    } else {
      d = new Date();
    }
    handleDateChange(d);
  }

  function buildDateIdentifierString(d) {
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  }

  async function handleDateChange(selectedDate) {
    console.log("selectedDate", selectedDate);
    setSearchParams({ currentDate: buildDateIdentifierString(selectedDate) });
    // setState({ ...state, currentDate: selectedDate });
    const data = await api.getPageData("events-list", [{ name: "currentDate", value: buildDateIdentifierString(selectedDate) }]);
    if (data) {
      console.log("data", data);
      setState({ currentDate: selectedDate, data: data });
    }
  }

  async function handleEventClicked(startTime, productId) {
    const starTimeEncoded = encodeURIComponent(startTime);
    const url = `/internal/event-overview?startTime=${starTimeEncoded}&productId=${productId}`;
    window.history.pushState({}, undefined, url);
    navigate(url, { replace: true });
  }

  async function handleSettingsIconClicked() {
    const url = `/internal/checkin-configuration`;
    window.history.pushState({}, undefined, url);
    navigate(url, { replace: true });
  }

  return (
    <Flex direction="column" align="center" w="full" minHeight={"100vh"}>
      <Box p={3} width={"100%"} maxWidth={800} borderWidth={1} borderRadius={8} boxShadow="lg" textAlign="left">
        <Flex alignContent={"space-between"}>
          <Heading width={"100%"} size="lg">
            Tour Events
          </Heading>
          <Box>
            <SettingsIcon boxSize={"30px"} cursor={"pointer"} onClick={handleSettingsIconClicked}></SettingsIcon>
          </Box>
        </Flex>
        <Text mt="1" mb="4">
          Click on an event below for more details.
        </Text>
        <DaySelector chosenDate={state.currentDate} handleDateChange={handleDateChange}></DaySelector>
        {state.data.map((eventGroup) => {
          return (
            <Box key={eventGroup.key}>
              <Text pl={"5%"} mt={4}>
                <b>{eventGroup.startDateFormatted}</b>
              </Text>
              <Table variant="simple" mt={1} colorScheme={"blackAlpha"}>
                <Tbody>
                  {eventGroup.events.map((event) => {
                    return (
                      <Tr
                        cursor={"pointer"}
                        key={event.key}
                        onClick={(e) => {
                          e.preventDefault();
                          handleEventClicked(event.startTime, event.productId);
                        }}
                      >
                        <Td py={3} pl={0} pr={2} textAlign={"center"} borderTopWidth={3} borderBottomWidth={3}>
                          {event.startTimeFormatted}
                        </Td>
                        <Td py={3} px={0} borderTopWidth={3} borderBottomWidth={3}>
                          {event.productName}
                        </Td>
                        <Td py={3} px={0} textAlign={"center"} borderTopWidth={3} borderBottomWidth={3}>
                          <Tag colorScheme={"blackAlpha"} variant={"outline"} fontStyle={"italic"}>
                            {event.numberOfPassengers} Booked
                          </Tag>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          );
        })}

        {api.errorMessage && (
          <Text mt={2} color="red.500">
            {api.errorMessage}
          </Text>
        )}
      </Box>
    </Flex>
  );
}

export default EventsList;
