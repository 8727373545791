import React, { useState, createContext, useEffect } from "react";

export const authContext = createContext(null);
// const serverUrl = process.env.NODE_ENV === "production" ? "https://checkin.secure.skelligdesign.com" : "http://localhost:8080";

const AuthProvider = ({ children }) => {
  const [error, setError] = useState("");

  useEffect(() => {
    // (async () => {
    //   try {
    //     const result = await axios(serverUrl + "/api/checkauth");
    //     console.log(result.data);
    //   } catch (e) {
    //     console.log(e.message);
    //   }
    // })();

    return () => {};
  }, []);

  return (
    <authContext.Provider value={{ error, setError }}>
      <>{children}</>
    </authContext.Provider>
  );
};

export default AuthProvider;
