import React, { useEffect, useState } from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  Accordion,
  HStack,
  AccordionItem,
  AccordionButton,
  Menu,
  AccordionPanel,
  MenuItemOption,
  Flex,
  Divider,
  Button,
  Box,
  Heading,
  Tag,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  MenuButton,
  MenuList,
  MenuItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  AccordionIcon,
  Grid,
  GridItem,
  SimpleGrid,
  TagLabel,
  TagRightIcon,
  TagLeftIcon,
  Link,
} from "@chakra-ui/react";
import {
  ArrowForwardIcon,
  Icon,
  ChatIcon,
  ChevronDownIcon,
  MoonIcon,
  PhoneIcon,
  EmailIcon,
  SettingsIcon,
  ArrowRightIcon,
  CalendarIcon,
  CheckCircleIcon,
  ArrowBackIcon,
  CloseIcon,
  DownloadIcon,
} from "@chakra-ui/icons";
import { CgProfile } from "react-icons/cg";
import { useApi } from "../../../hooks/internal/useApi";
// import {authContext} from "../contexts/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";

function ContactsExport() {
  const [searchParams, setSearchParams] = useSearchParams();
  // const authContext = useContext(authContext);
  const api = useApi();
  const navigate = useNavigate();

  const [state, setState] = useState({
    data: [],
  });

  async function initPage() {
    const startTimeParam = searchParams.get("startTime");
    const productIdParam = searchParams.get("productId");
    console.log("startTimeParam", startTimeParam);
    const data = await api.getPageData("contacts-export-initial", [
      { name: "startTime", value: encodeURIComponent(startTimeParam) },
      { name: "productId", value: productIdParam },
    ]);
    if (data) {
      console.log("data", data);
      setState(() => {
        return {
          ...state,
          data: data,
        };
      });
    }
  }

  useEffect(() => {
    api.checkAuth();
    initPage();
    return () => {};
  }, []);

  function handleArrowBackIconClicked() {
    navigate(-1);
  }

  function handleFirstNameChanged(e, eventId, bookingNumber, contactNum) {
    setState((prevState) => {
      const newEvents = prevState.data.map((event) => {
        const newBookings = event.contacts.map((contact) => {
          if (contact.eventId === eventId && contact.bookingNumber === bookingNumber && contact.contactNum === contactNum) {
            return { ...contact, firstName: e.target.value };
          }
          return contact;
        });
        return { ...event, contacts: newBookings };
      });
      return { ...prevState, data: newEvents };
    });
  }

  function handleLastNameChanged(e, eventId, bookingNumber, contactNum) {
    setState((prevState) => {
      const newEvents = prevState.data.map((event) => {
        const newBookings = event.contacts.map((contact) => {
          if (contact.eventId === eventId && contact.bookingNumber === bookingNumber && contact.contactNum === contactNum) {
            return { ...contact, lastName: e.target.value };
          }
          return contact;
        });
        return { ...event, contacts: newBookings };
      });
      return { ...prevState, data: newEvents };
    });
  }

  function handleNumberChanged(e, eventId, bookingNumber, contactNum) {
    setState((prevState) => {
      const newEvents = prevState.data.map((event) => {
        const newBookings = event.contacts.map((contact) => {
          if (contact.eventId === eventId && contact.bookingNumber === bookingNumber && contact.contactNum === contactNum) {
            return { ...contact, phoneNumber: e.target.value };
          }
          return contact;
        });
        return { ...event, contacts: newBookings };
      });
      return { ...prevState, data: newEvents };
    });
  }

  function handleDeleteClicked(e, eventId, bookingNumber, contactNum) {
    setState((prevState) => {
      const newEvents = prevState.data.map((event) => {
        const newBookings = event.contacts.filter((contact) => {
          const isContactClicked = contact.eventId === eventId && contact.bookingNumber === bookingNumber && contact.contactNum === contactNum;
          return !isContactClicked;
        });
        return { ...event, contacts: newBookings };
      });
      return { ...prevState, data: newEvents };
    });
  }

  function createContactsCSV() {
    let csvString = "";
    let headingsForCsv = ["Given Name", "Family Name", "E-mail 1 - Value", "Phone 1 - Value"];
    // for (const property in userForHeadings) {
    //     userProperties.push(property);
    //     const escapedProperty = `\"${property}\"`;
    //     userPropertiesForCsv.push(escapedProperty);
    // }
    const properties = ["firstName", "lastName", "emailAddress", "phoneNumber"];
    csvString += headingsForCsv.join(",") + "\n";

    for (const event of state.data) {
      for (const contact of event.contacts) {
        const rowValues = [];
        for (const property of properties) {
          const value = contact[property];
          rowValues.push(`\"${value}\"`);
          // if (typeof value === "string") {
          //   rowValues.push(`\"${value}\"`);
          // } else {
          //   rowValues.push(value);
          // }
        }
        csvString += rowValues.join(",") + "\n";
      }
    }
    console.log("Final CSV", csvString);

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvString);
    hiddenElement.target = "_blank";
    hiddenElement.download = `${state.data[0].csvTitle}.csv`;
    hiddenElement.click();
  }

  return (
    <Flex direction="column" align="center" w="full" minHeight={"100vh"}>
      {state.data.map((event) => {
        return (
          <Box key={event.eventId} py={2} px={2} width={"100%"} maxWidth={800} borderWidth={1} borderRadius={8} boxShadow="lg" textAlign="left">
            <Heading size="lg"></Heading>
            <Flex alignContent={"flex-start"}>
              <ArrowBackIcon boxSize={"35px"} cursor={"pointer"} onClick={handleArrowBackIconClicked}></ArrowBackIcon>
              <Heading size="lg" pl={2}>
                {event.productName}
              </Heading>
              <DownloadIcon cursor={"pointer"} marginLeft={"auto"} alignSelf={"center"} onClick={createContactsCSV}></DownloadIcon>
            </Flex>

            <Box maxWidth={"100%"}>
              <Text mt="1" mb="2" fontWeight={"bold"} fontSize="19px">
                {event.formattedStartTime}
              </Text>

              {event.contacts.map((contact) => {
                return (
                  <Box key={contact.contactKey} mt={2} borderRadius="5px" p={1} boxShadow={"1px 1px 2px 1px lightgrey"}>
                    <Flex width={"100%"} mt={1} mb={1} mr={"45px"} justifyContent={"flex-end"}>
                      <CloseIcon
                        fontSize={"10px"}
                        onClick={(e) => {
                          handleDeleteClicked(e, contact.eventId, contact.bookingNumber, contact.contactNum);
                        }}
                        cursor={"pointer"}
                      ></CloseIcon>
                    </Flex>
                    <Flex justifyContent={"space-between"}>
                      <Input
                        fontSize={"15px"}
                        value={contact.firstName}
                        size="sm"
                        onChange={(e) => {
                          handleFirstNameChanged(e, contact.eventId, contact.bookingNumber, contact.contactNum);
                        }}
                      />
                      <Input
                        fontSize={"15px"}
                        value={contact.lastName}
                        size="sm"
                        onChange={(e) => {
                          handleLastNameChanged(e, contact.eventId, contact.bookingNumber, contact.contactNum);
                        }}
                      />
                    </Flex>

                    <InputGroup size="sm">
                      <InputLeftElement pointerEvents="none" children={<EmailIcon />} />
                      <Input type="email" value={contact.emailAddress} disabled={true} color={"gray.600"} backgroundColor={"gray.100"} style={{ opacity: 1 }} />
                    </InputGroup>
                    <InputGroup size="sm">
                      <InputLeftElement pointerEvents="none" children={<PhoneIcon />} />
                      <Input
                        type="tel"
                        value={contact.phoneNumber}
                        onChange={(e) => {
                          handleNumberChanged(e, contact.eventId, contact.bookingNumber, contact.contactNum);
                        }}
                      />
                    </InputGroup>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}

      {api.errorMessage && (
        <Text mt={2} color="red.500">
          {api.errorMessage}
        </Text>
      )}
    </Flex>
  );
}

export default ContactsExport;
